import React, { useState } from "react";
import styled from "styled-components";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import { makeStyles } from "@material-ui/styles";
import DeleteCountyModal from "./delete.country.modal";
import EditCountryModal from "./edit.country.modal";
import { useHistory, useParams } from "react-router-dom";
import { useStudyPlan } from "../../contexts/study.context";
import SectionHeader from "../section.header";
import TextButton from "../buttons/text";
import { otherStyles } from "../../helpers/utils";

const CountryHeader = ({
  title,
  countryId,
  siteGroup,
  variant = "primary",
  hasDelete = true,
  hasEdit = true,
  studyPlanType,
  currentEffort,
  currentTimeframe,
  tab,
}) => {
  const history = useHistory();
  const { id, planId } = useParams();
  const { canEditPlan } = useStudyPlan();
  const [open, setOpen] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const otherClasses = otherStyles();

  return (
    <>
      <HeaderContainer>
        <SectionHeader paddingBottom={false}>{title}</SectionHeader>
        <div style={{ display: "flex" }}>
          {canEditPlan && hasEdit === false && (
            <TextButton
              style={{ marginBlock: "1em", marginInline: "0.6em" }}
              onClick={() =>
                history.push(
                  `/ctpo/study-plans/${id}/${planId}/countries-and-site-groups#country-inputs`,
                  { tabIndex: tab },
                )
              }
            >
              <EditIcon style={{ marginRight: ".3em" }} /> Edit
            </TextButton>
          )}
          {hasEdit && (
            <IconButton
              style={{ marginBlock: "0.4em", marginRight: "0.2em" }}
              className={otherClasses.iconButton}
              onClick={() => setOpenEdit(true)}
            >
              <EditIcon className={otherClasses.iconButton} />
            </IconButton>
          )}
          {hasDelete && (
            <IconButton
              style={{ marginBlock: "0.4em", marginRight: "0.4em" }}
              className={otherClasses.iconButton}
              onClick={() => setOpen(true)}
            >
              <DeleteIcon className={otherClasses.iconButton} />
            </IconButton>
          )}
        </div>
      </HeaderContainer>
      {hasDelete && (
        <DeleteCountyModal
          countryId={countryId}
          siteGroup={siteGroup}
          open={open}
          onClose={() => setOpen(false)}
        />
      )}

      {hasEdit && openEdit && (
        <EditCountryModal
          countryId={countryId}
          siteGroup={siteGroup}
          studyPlanType={studyPlanType}
          open={openEdit}
          onClose={() => setOpenEdit(false)}
          currentEffort={currentEffort}
          currentTimeframe={currentTimeframe}
        />
      )}
    </>
  );
};

export default CountryHeader;

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  background-color: var(--surface-50);
  border-radius: 4px;
`;

const useStyles = makeStyles((theme) => ({
  root: {
    color: "var(--teal-700)",
    "&:hover": {
      backgroundColor: "var(--teal-100)",
    },
    "&:active": {
      backgroundColor: "var(--teal-300)",
    },
    "&:focus": {
      outline: "1px solid var(--teal-500)",
      outlineOffset: "4px",
    },
    "&:disabled": {
      opacity: 0.6,
    },
  },
}));
