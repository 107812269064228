import { LinearProgress } from "@material-ui/core";
import React, { useState } from "react";
import API from "../../../../app/utils/api";
import FileUploader from "../../../../app/components/file.uploader.excel";
import CustomModal from "../../../../app/components/modal.no.close";
import styled from "styled-components";
import { useSnackbar } from "../../../../app/contexts/snackbar.context";
import * as XLSX from "xlsx";
import PrimaryButton from "../../../../app/components/buttons/primary";
import OutlinedButton from "../../../../app/components/buttons/outlined";

const UploadContractModal = ({ onContinue, ...props }) => {
  const { showSnackbar } = useSnackbar();
  const [progress, setProgress] = useState(0);
  const [status, setStatus] = useState("");
  const [loading, setLoading] = useState(false);
  const [apiStatus, setApiStatus] = useState(null);
  const [uploadErrors, setUploadErrors] = useState([]);

  // Helper function to handle merged cells
  const getMergedCellValue = (worksheet, cellAddress) => {
    // Check if the cell is part of a merged range
    const merges = worksheet["!merges"] || [];

    // Find if our cell is in any merged range
    const cellRef = XLSX.utils.decode_cell(cellAddress);

    for (const merge of merges) {
      if (
        cellRef.r >= merge.s.r &&
        cellRef.r <= merge.e.r &&
        cellRef.c >= merge.s.c &&
        cellRef.c <= merge.e.c
      ) {
        // This cell is part of a merged range
        // The value is stored at the top-left cell of the merged range
        const topLeftCellAddress = XLSX.utils.encode_cell(merge.s);
        return worksheet[topLeftCellAddress]?.v;
      }
    }

    // If not in a merged range, return the cell's own value
    return worksheet[cellAddress]?.v;
  };

  // Find a value in a range of cells, handling merged cells
  const findCellWithValueInRange = (
    worksheet,
    value,
    startRow,
    endRow,
    startCol,
    endCol,
  ) => {
    for (let row = startRow; row <= endRow; row++) {
      for (let col = startCol; col <= endCol; col++) {
        const cellAddress = XLSX.utils.encode_cell({ r: row, c: col });
        const cellValue = getMergedCellValue(worksheet, cellAddress);

        if (cellValue === value) {
          return { row, col };
        }
      }
    }
    return null; // Not found
  };

  const processSheetData = (worksheet) => {
    const assessments = [];
    const visits = [];
    let visitNames = [];
    let visitCosts = [];

    // Get sheet dimensions
    const range = XLSX.utils.decode_range(worksheet["!ref"]);
    const maxRow = range.e.r;
    const maxCol = range.e.c;

    // Find the "Predict Visit Name" cell, checking all cells in first few columns
    const predictVisitCell = findCellWithValueInRange(
      worksheet,
      "Predict Visit Name",
      0,
      maxRow,
      0,
      3, // Check columns A through D (0-3)
    );

    if (!predictVisitCell) {
      showSnackbar("Could not find 'Predict Visit Name' in the sheet", "error");
    }

    const visitsRowIndex = predictVisitCell.row;

    // Get visit names starting from column E (index 4)
    // We need to check each cell individually to handle merged cells correctly
    visitNames = [];
    for (let col = 4; col < maxCol; col++) {
      const cellAddress = XLSX.utils.encode_cell({ r: visitsRowIndex, c: col });
      const cellValue = getMergedCellValue(worksheet, cellAddress);

      // Skip null values and the "Total" column (usually the last one)
      if (cellValue && cellValue !== "Total") {
        visitNames.push(cellValue);
      }
    }

    // Find the visit costs row (where "Total Cost Per Visit" appears)
    const costCell = findCellWithValueInRange(
      worksheet,
      "Total Cost Per Visit",
      0,
      maxRow,
      0,
      3, // Check columns A through D (0-3)
    );

    if (!costCell) {
      showSnackbar(
        "Could not find 'Total Cost Per Visit' in the sheet",
        "error",
      );
    }

    const costsRowIndex = costCell.row;

    // Get visit costs matching the visit names
    visitCosts = [];
    for (let i = 0; i < visitNames.length; i++) {
      const col = i + 4; // Start from column E (index 4)
      const cellAddress = XLSX.utils.encode_cell({ r: costsRowIndex, c: col });
      const costValue = getMergedCellValue(worksheet, cellAddress);

      // Parse cost value
      const cost =
        typeof costValue === "number" ? costValue : parseFloat(costValue);
      if (!isNaN(cost)) {
        visitCosts.push(cost);
      } else {
        visitCosts.push(0); // Default to 0 if cost can't be parsed
      }
    }

    // Validate that we have matching numbers of names and costs
    if (visitNames.length !== visitCosts.length) {
      showSnackbar(
        `Mismatch between number of visit names (${visitNames.length}) and visit costs (${visitCosts.length})`,
        "error",
      );
    }

    // Create visits array
    visitNames.forEach((visitName, index) => {
      visits.push({
        visitName,
        visitCost: visitCosts[index],
      });
    });

    // Process assessments (any row with a value in column A)
    for (let row = 0; row <= maxRow; row++) {
      const cellAddress = XLSX.utils.encode_cell({ r: row, c: 0 }); // Column A
      const assessmentName = getMergedCellValue(worksheet, cellAddress);

      if (assessmentName) {
        const costCellAddress = XLSX.utils.encode_cell({ r: row, c: 3 }); // Column D
        const costValue = getMergedCellValue(worksheet, costCellAddress);
        const assessmentCost =
          typeof costValue === "number" ? costValue : parseFloat(costValue);

        if (!isNaN(assessmentCost)) {
          assessments.push({
            assessmentName,
            assessmentCost,
          });
        }
      }
    }

    return { assessments, visits };
  };

  const handleChange = async (files) => {
    if (files.length === 0) return;
    handleUpload(files);
  };

  const handleUpload = async (files) => {
    if (files.length === 0) return;

    setLoading(true);
    setProgress(0);
    setStatus("Processing site contract...");
    setUploadErrors([]);

    try {
      const reader = new FileReader();
      reader.onload = async (event) => {
        const data = new Uint8Array(event.target.result);
        const workbook = XLSX.read(data, {
          type: "array",
          cellDates: true,
          cellNF: true,
          cellStyles: true,
        });

        // Find the 'Per Procedure' sheet
        const worksheet = workbook.Sheets["Per Procedure"];
        if (!worksheet) {
          showSnackbar(
            "Could not find 'Per Procedure' sheet in the workbook",
            "error",
          );
          setLoading(false);
          setUploadErrors([
            "Could not find 'Per Procedure' sheet in the workbook",
          ]);
          return;
        }

        try {
          const { assessments, visits } = processSheetData(worksheet);

          if (assessments.length === 0) {
            showSnackbar(
              "No valid assessment data found in the sheet",
              "error",
            );
            setLoading(false);
            setUploadErrors(["No valid assessment data found in the sheet"]);
            return;
          }

          const siteData = {
            scheduleOfEventsId: props.scheduleId,
            siteNumber: props.siteNumber,
            siteId: props.siteId,
            assessments,
            visits,
          };

          setProgress(50);
          console.log(siteData);
          const response = await API.uploadContract(props.studyId, siteData);

          if (response.status === 200) {
            showSnackbar("Site contract uploaded successfully", "success");
            setProgress(100);
            setStatus("Upload completed.");
            setApiStatus(200);
          } else {
            showSnackbar("Failed to upload site contract", "error");
            setProgress(100);
            setStatus("Upload failed.");
            setApiStatus(response.status);
            setUploadErrors([
              response.data?.message || "Unknown error occurred",
            ]);
          }
        } catch (error) {
          setUploadErrors([error.message]);
          setProgress(100);
          setStatus("Upload failed.");
          setApiStatus("error");
        }
      };
      reader.readAsArrayBuffer(files[0]);
    } catch (error) {
      showSnackbar("Failed to process file", "error");
      setLoading(false);
      setUploadErrors([error.message]);
    }
  };

  const handleContinue = () => {
    props.onClose();
    onContinue();
  };

  return (
    <CustomModal
      size={700}
      {...props}
      onClose={!loading && props.onClose}
      aria-labelledby="Upload Site Contract"
      aria-describedby="upload-contract"
      title="Upload Site Contract"
    >
      <Container>
        {!loading && (
          <VContainer>
            <FileUploader
              acceptedFiles={{
                "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
                  [],
              }}
              onFileUpload={handleChange}
            />
          </VContainer>
        )}
        {loading && (
          <VContainer>
            {status !== "Upload completed." && status !== "Upload failed." && (
              <LinearProgress
                variant="determinate"
                value={progress}
              />
            )}
            <StatusText>{status}</StatusText>
            {uploadErrors.length > 0 && (
              <ErrorContainer>
                <ErrorTitle>Errors Found:</ErrorTitle>
                {uploadErrors.map((error, index) => (
                  <ErrorText key={index}>{error}</ErrorText>
                ))}
              </ErrorContainer>
            )}
          </VContainer>
        )}
        <ButtonContainer>
          {loading && apiStatus === 200 ? (
            <PrimaryButton onClick={handleContinue}>Continue</PrimaryButton>
          ) : (
            <OutlinedButton onClick={props.onClose}>Cancel</OutlinedButton>
          )}
        </ButtonContainer>
      </Container>
    </CustomModal>
  );
};

export default UploadContractModal;

const VContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 2em;
  padding-top: 0;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2em;
  padding: 1em;
  padding-bottom: 0em;
  padding-top: 0;
  max-height: 75vh;
  overflow-y: auto;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 1em;
`;

const StatusText = styled.div`
  font-weight: 500;
  margin-bottom: 1em;
`;

const ErrorContainer = styled.div`
  background-color: #fff3f3;
  border: 1px solid #ffcdd2;
  border-radius: 4px;
  padding: 1em;
  margin-top: 1em;
`;

const ErrorTitle = styled.div`
  color: #d32f2f;
  font-weight: 500;
  margin-bottom: 0.5em;
`;

const ErrorText = styled.div`
  color: #d32f2f;
  font-size: 0.9em;
  margin-bottom: 0.25em;
`;
