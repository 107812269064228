import React, { useState } from "react";
import styled from "styled-components";

import MuiPaper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import { SectionHeader } from "../../app/components";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory, useParams } from "react-router-dom";

const SitegroupSearch = ({ sitegroups }) => {
  const [filter, setFilter] = useState("");
  const classes = useStyles();
  const history = useHistory();
  const { id } = useParams();

  const handleSelect = (id) => history.push(`/ctpo/admin-data/${id}`);

  const filteredSitegroups = filter
    ? sitegroups.filter((item) =>
        item.siteGroupName?.toLowerCase().includes(filter.toLowerCase()),
      )
    : sitegroups;

  return (
    <Paper>
      <Container>
        <SectionHeader
          hasStartAdornment={false}
          marginBottom={false}
          useBackground={false}
        >
          Site Groups
        </SectionHeader>
        <TextField
          variant="outlined"
          placeholder="Search..."
          onChange={({ target }) => setFilter(target.value)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon style={{ color: "var(--teal-700)" }} />
              </InputAdornment>
            ),
          }}
        />
        <Margin>
          <Divider />
        </Margin>
      </Container>
      <SiteGroupListContainer>
        <List>
          {filteredSitegroups.map((sitegroup) => (
            <ListItem
              button
              key={sitegroup.siteGroupId}
              onClick={() => handleSelect(sitegroup.siteGroupId)}
            >
              <Typography
                className={
                  id === sitegroup.siteGroupId.toString() ? classes.root : ""
                }
              >
                {sitegroup.siteGroupName}
              </Typography>
            </ListItem>
          ))}
        </List>
      </SiteGroupListContainer>
    </Paper>
  );
};

export default SitegroupSearch;

const Container = styled.div`
  height: max-content;
  display: grid;
  grid-gap: 1em;
`;

const Paper = styled(MuiPaper)`
  height: 100%;
  max-height: 100%;
  overflow: scroll;
  overflow-x: hidden;
  padding: 1em;
`;

const SiteGroupListContainer = styled.div``;

const Margin = styled.div`
  margin: 1em 0;
`;

const useStyles = makeStyles({
  root: {
    color: "var(--teal-700)",
    fontWeight: 600,
  },
});
