import React, { useRef } from "react";
import MuiAccordion from "@material-ui/core/Accordion";
import MuiAccordionSummary from "@material-ui/core/AccordionSummary";
import MuiAccordionDetails from "@material-ui/core/AccordionDetails";
import ArrowForwardIosSharpIcon from "@material-ui/icons/ArrowForwardIosSharp";
import { makeStyles } from "@material-ui/styles";
import Typography from "@material-ui/core/Typography";
import { useHistory } from "react-router-dom";

const Accordion = ({
  scrollIntoView,
  defaultExpanded = undefined,
  ...props
}) => {
  const history = useHistory();
  const classes = useStyles();
  const ref = useRef();

  return (
    <>
      {scrollIntoView ? (
        <MuiAccordion
          style={{ marginTop: "1em" }}
          {...(defaultExpanded !== undefined && { expanded: defaultExpanded })}
          ref={ref}
          onChange={(e, isExpanded) => {
            if (isExpanded) {
              setTimeout(() => {
                ref.current.scrollIntoView({
                  behavior: "smooth",
                  block: "center",
                });
              }, 200);
            } else {
              history.push(`${history.location.pathname}`);
            }
          }}
          {...props}
        >
          <MuiAccordionSummary
            expandIcon={<ArrowForwardIosSharpIcon />}
            className={classes.summary}
          >
            <Typography className={classes.heading}>{props.summary}</Typography>
          </MuiAccordionSummary>
          <MuiAccordionDetails className={classes.root}>
            {props.children}
          </MuiAccordionDetails>
        </MuiAccordion>
      ) : (
        <MuiAccordion {...props}>
          <MuiAccordionSummary
            expandIcon={<ArrowForwardIosSharpIcon />}
            className={classes.summary}
          >
            <Typography className={classes.heading}>{props.summary}</Typography>
          </MuiAccordionSummary>
          <MuiAccordionDetails className={classes.root}>
            {props.children}
          </MuiAccordionDetails>
        </MuiAccordion>
      )}
    </>
  );
};

export default Accordion;

const useStyles = makeStyles((theme) => ({
  heading: {
    color: "var(--text-primary)",
    fontSize: "1.2rem",
    paddingLeft: "8px",
  },
  root: {
    padding: 0,
  },
  summary: {
    flexDirection: "row-reverse",
    "& .MuiAccordionSummary-expandIcon.Mui-expanded": {
      transform: "rotate(90deg)",
      transition: theme.transitions.create("transform", {
        duration: theme.transitions.duration.shortest,
      }),
    },
  },
}));
