import { Button, Typography } from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import React, { useEffect, useMemo } from "react";
import { Tooltip } from "recharts";
import styled from "styled-components";
import { SectionHeader } from "../../../../app/components";
import moment from "moment";
import { useStudyPlan } from "../../../../app/contexts/study.context";
import TableRow from "./table.row";
import { isNumberNotEmpty } from "../../utils/helpers";
import TableHeader from "./table.header";
import { useHistory, useParams } from "react-router-dom";
import SeparateContainer from "../../../../app/components/separate.Container";
import TextButton from "../../../../app/components/buttons/text";

export default function SequentialCohortSetupTable(props) {
  const {
    studyPlan,
    seqCohorts,
    setSeqCohorts,
    seqCohortColumnHeaders,
    seqCohortsStatus,
    canEditPlan,
  } = useStudyPlan();
  const history = useHistory();
  const { id, planId } = useParams();
  const croStartDate = useMemo(
    () =>
      studyPlan.croProjectStartDateMillis
        ? moment.utc(studyPlan.croProjectStartDateMillis).format("D/MMM/YY")
        : "",
    [studyPlan],
  );
  const fpsStartDate = useMemo(() => {
    if (studyPlan) {
      const { countries } = studyPlan;
      if (countries) {
        const lCountries = [...countries];
        const fpsDateValues = lCountries.map((country) => {
          const { siteGroups } = country;
          const { milestones } = siteGroups[0];
          let dateValue = "";
          milestones.forEach((ml) => {
            if (ml["milestoneName"] === "DURATION_TO_FPS")
              dateValue = ml["milestoneDateMillis"];
          });
          return dateValue;
        });

        return moment.utc(Math.min(...fpsDateValues)).format("D/MMM/YY");
      }
    }
  }, [studyPlan]);

  useEffect(() => {
    if (seqCohortsStatus === "resolved") {
      // trigger table value calculation and formatting on page load
      handleSeqValueChange("test", "test", "test", "test");
    }
    // eslint-disable-next-line
  }, [seqCohortsStatus]);

  const getPrevFpsStartDate = (
    newStudyPlanValues,
    predecessorCohortName,
    predecessorActivity,
  ) => {
    const prevCohort = newStudyPlanValues.filter(
      (nsv) => nsv.cohortName && nsv.cohortName === predecessorCohortName,
    );
    const activityEndKey =
      predecessorActivity === "Screening"
        ? "screeningEndDate"
        : predecessorActivity === "Enrolling"
        ? "enrollingEndDate"
        : predecessorActivity === "DTL"
        ? "dtlEndDate"
        : predecessorActivity === "SRC"
        ? "srcEndDate"
        : "";
    if (!prevCohort.length || !activityEndKey || !prevCohort[0][activityEndKey])
      return null;
    return prevCohort[0][activityEndKey];
  };

  const updateCalculatedFields = (newStudyPlanValues) => {
    const lNewStudyPlanValues = [...newStudyPlanValues];
    return newStudyPlanValues.map((npv, index) => {
      const lFpsStartDate =
        index === 0
          ? fpsStartDate
          : getPrevFpsStartDate(
              lNewStudyPlanValues,
              lNewStudyPlanValues[index].predecessorCohortName,
              lNewStudyPlanValues[index].predecessorActivity,
            );
      if (!lFpsStartDate) return npv;
      const cohortStartDate =
        !isNaN(npv.lagDays) && lFpsStartDate
          ? moment
              .utc(lFpsStartDate, "D/MMM/YY")
              .add("days", npv.lagDays)
              .format("D-MMM-YY")
          : "";
      const cohortStartDay =
        cohortStartDate && croStartDate
          ? Math.abs(
              moment
                .utc(cohortStartDate, "D-MMM-YY")
                .diff(moment.utc(croStartDate, "D/MMM/YY"), "days"),
            )
          : "";

      const screeningEndDate =
        cohortStartDate && isNumberNotEmpty(npv.screeningDurationDays)
          ? moment
              .utc(cohortStartDate, "D-MMM-YY")
              .add("days", npv.screeningDurationDays)
              .format("D-MMM-YY")
          : "";
      const screeningStudyDay =
        screeningEndDate && croStartDate
          ? Math.abs(
              moment
                .utc(screeningEndDate, "D-MMM-YY")
                .diff(moment.utc(croStartDate, "D/MMM/YY"), "days"),
            )
          : "";
      const enrollingEndDate =
        screeningEndDate && isNumberNotEmpty(npv.enrollingDurationDays)
          ? moment
              .utc(screeningEndDate, "D-MMM-YY")
              .add("days", npv.enrollingDurationDays)
              .format("D-MMM-YY")
          : "";
      const enrollingEndDay =
        enrollingEndDate && croStartDate
          ? Math.abs(
              moment
                .utc(enrollingEndDate, "D-MMM-YY")
                .diff(moment.utc(croStartDate, "D/MMM/YY"), "days"),
            )
          : "";
      const dtlEndDate =
        enrollingEndDate && isNumberNotEmpty(npv.dtlDurationDays)
          ? moment
              .utc(enrollingEndDate, "D-MMM-YY")
              .add("days", npv.dtlDurationDays)
              .format("D-MMM-YY")
          : "";
      const dtlEndDay =
        dtlEndDate && croStartDate
          ? Math.abs(
              moment
                .utc(dtlEndDate)
                .diff(moment.utc(croStartDate, "D/MMM/YY"), "days"),
            )
          : "";
      const srcEndDate =
        dtlEndDate && isNumberNotEmpty(npv.srcDurationDays)
          ? moment
              .utc(dtlEndDate, "D-MMM-YY")
              .add("days", npv.srcDurationDays)
              .format("D-MMM-YY")
          : "";
      const srcEndDay =
        srcEndDate && croStartDate
          ? Math.abs(
              moment
                .utc(srcEndDate)
                .diff(moment.utc(croStartDate, "D/MMM/YY"), "days"),
            )
          : "";

      const predecessorActivity = npv.predecessorActivity;
      const displayPredecessorActivity =
        predecessorActivity === "DTL"
          ? seqCohortColumnHeaders.columnHeader3
          : predecessorActivity === "SRC"
          ? seqCohortColumnHeaders.columnHeader4
          : predecessorActivity;
      const newNpv = {
        ...npv,
        cohortStartDate: cohortStartDate,
        cohortStartDay: cohortStartDay,
        screeningEndDate: screeningEndDate,
        screeningEndDay: screeningStudyDay,
        enrollingEndDate: enrollingEndDate,
        enrollingEndDay: enrollingEndDay,
        dtlEndDate: dtlEndDate,
        dtlEndDay: dtlEndDay,
        srcEndDate: srcEndDate,
        srcEndDay: srcEndDay,
        predecessorActivity: displayPredecessorActivity,
      };
      lNewStudyPlanValues[index] = newNpv;
      return newNpv;
    });
  };

  const isUnique = (cohorts, value) => {
    return !cohorts.filter((c) => c.cohortName === value).length;
  };

  const handleSeqValueChange = (name, value, key, type = "number") => {
    if (name === "cohortName" && value) {
      if (!isUnique(seqCohorts, value)) return;
    }
    let newSeqCohorts = seqCohorts.map((sp) => {
      if ((sp.studyPlanCohortId || sp.key) === key) {
        // checking the number inputs to not have a negative value - this doesn't include the lag field
        if (type === "number" && value) {
          if (parseInt(value) < 0) return sp;
        }
        return {
          ...sp,
          [name]: type === "number" && value ? parseInt(value) : value,
        };
      }
      return sp;
    });

    newSeqCohorts = updateCalculatedFields(newSeqCohorts);

    setSeqCohorts(newSeqCohorts);
    // setSeqCohortChangeLog({ someValue: "seq cohort change" });
  };

  const renderTableRows = () => {
    return seqCohorts.map((sp, index) => {
      return (
        <TableRow
          key={sp.key || sp.studyPlanCohortId}
          sp={sp}
          index={index}
        />
      );
    });
  };

  const tableData = [
    { label: "CRO Start Date", value: croStartDate },
    { label: "Start Date (FPS)", value: fpsStartDate },
  ];

  return (
    <SeparateContainer>
      <Container>
        <SideBySide>
          <SectionHeader
            paddingBottom={false}
            endAdornment={<Tooltip info="Sequential Cohort Setup" />}
          >
            Sequential Cohort Table
          </SectionHeader>
          {canEditPlan && (
            <TextButton
              style={{ marginBlock: "1em", marginInline: "0.6em" }}
              onClick={() =>
                history.push(
                  `/ctpo/study-plans/${id}/${planId}/countries-and-site-groups#sequential-cohort-setup`,
                  { tabIndex: props.tab },
                )
              }
            >
              <EditIcon style={{ marginRight: ".3em" }} /> Edit
            </TextButton>
          )}
        </SideBySide>
        <div style={{ padding: "20px" }}>
          <Section>
            <Table noBorder>
              {tableData.map((data, index) => (
                <Row
                  key={index}
                  noBorder
                >
                  <Cell
                    fullBorder
                    width={`${(8 / 70) * 100}%`}
                    variant="header"
                  >
                    <Typography variant="body2">
                      <strong>{data.label}</strong>
                    </Typography>
                  </Cell>
                  <Cell
                    rightBorder
                    width={`${(8 / 70) * 100}%`}
                  >
                    {data.value}
                  </Cell>
                </Row>
              ))}
            </Table>
          </Section>
        </div>
        <div style={{ padding: "10px 20px 20px 20px" }}>
          <Table>
            <TableHeader />
            {renderTableRows()}
          </Table>
        </div>
      </Container>
    </SeparateContainer>
  );
}

const Section = styled.div`
  margin-bottom: 1rem;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const Row = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  height: 50px;
  border-bottom: ${(p) =>
    p.noBorder ? "none" : "1px solid var(--surface-300)"};
  &:last-child {
    border: none;
  }
`;

const Cell = styled.div`
  background: ${(p) =>
    p.variant === "header" ? "var(--surface-100)" : "white"};
  height: 100%;
  align-items: center;
  justify-content: center;
  display: flex;
  width: ${(p) => (p.width ? p.width : "100%")};
  border-right: 1px solid var(--surface-300);
  padding: 5px;
  text-align: center;
  border-left: ${(p) =>
    p.leftBorder ? "1px solid var(--surface-300)" : "none"};
  &:last-child {
    border: ${(p) => (p.rightBorder ? "1px solid var(--surface-300)" : "none")};
  }
  border: ${(p) => (p.fullBorder ? "1px solid var(--surface-300)" : "")};
`;

const Table = styled.div`
  display: flex;
  flex-direction: column;
  border: ${(p) => (p.noBorder ? "none" : "1px solid var(--surface-300)")};
  border-radius: 4px;
  width: 100%;
`;

const SideBySide = styled.div`
  display: flex;
  justify-content: space-between;
  background-color: var(--surface-50);
  border-radius: 4px;
  margin-bottom: 1em;
`;
