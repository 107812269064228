import React, { useEffect, useMemo } from "react";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import { Button } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import styled from "styled-components";
import { useParams } from "react-router-dom";

import { ASSESSMENT_TYPES_WITH_SITE_COST } from "./table.utils";
import { Accordion } from "../../../../app/components";
import TableHeader from "./table.header";
import TableRow from "./table.row";
import { getScheduleOfEventsAssessmentTableDefaults } from "../../../../app/contexts/helpers";
import { isNumberNotEmpty } from "../../utils/helpers";
import { useScheduleEvents } from "../../../../app/contexts/schedule.events.context";

/*
  This component is responsible for rendering the table of assessments
  It uses the schedule events context to handle the schedule of events.
  It uses the study context to handle the study plan.
  
  Props:
  - value: number - controls the display mode (0 for edit mode, 1 for view mode)
  - country: object - contains country information including siteGroupId
  - fieldType: string - determines the type of comments field
  - siteId: string - the ID of the current site
  - setTableVisibility: function - controls table visibility
  - tableVisibility: object - current visibility state
*/
export default function AssessmentsTable({
  value,
  country,
  fieldType,
  siteId,
  setTableVisibility,
  tableVisibility,
}) {
  const { id, scheduleId } = useParams();
  const {
    scheduleOfEventsAssessments,
    setScheduleOfEventsAssessmentsChangeLog,
    scheduleOfEventsAssessmentsChangeLog,
    setScheduleOfEventsAssessments,
    setVisitsAndAssessmentsMappingChangeLog,
    fetchSOEAssessments,
    loadingCountrySOE,
    assessmentNames,
    allAssessmentNames,
  } = useScheduleEvents();

  // Create a consistent key for accessing data
  const dataKey = useMemo(() => {
    if (country.siteGroupId && siteId) {
      return `${country.siteGroupId}-${siteId}`;
    }
    return country.siteGroupId || "study";
  }, [country.siteGroupId, siteId]);

  // Determine if we're in country view
  const isCountry = useMemo(() => {
    return Boolean(country.siteGroupId);
  }, [country.siteGroupId]);

  // Calculate column count based on view type
  const columnCount = useMemo(() => (isCountry ? 5 : 14.5), [isCountry]);

  // Calculate remove column width
  const removeColWidth = value === 0 ? 5 : 0;
  const typeColumnWidth = 22;

  // Ensure data exists for the current view
  useEffect(() => {
    const shouldFetchData = isCountry && !scheduleOfEventsAssessments[dataKey];

    if (shouldFetchData) {
      (async () => {
        await fetchSOEAssessments(
          scheduleId,
          id,
          isCountry,
          country.siteGroupId,
          siteId,
        );
      })();
    }
  }, [
    dataKey,
    isCountry,
    siteId,
    scheduleId,
    id,
    country.siteGroupId,
    scheduleOfEventsAssessments,
    fetchSOEAssessments,
  ]);

  const addNewRow = () => {
    const newData = {
      ...scheduleOfEventsAssessments,
      [dataKey]: [
        ...(scheduleOfEventsAssessments[dataKey] || []),
        getScheduleOfEventsAssessmentTableDefaults(
          parseInt(scheduleId || "0"),
          id,
          { assessmentNames: allAssessmentNames },
        ),
      ],
    };

    setScheduleOfEventsAssessments(newData);
    setScheduleOfEventsAssessmentsChangeLog({
      ...scheduleOfEventsAssessmentsChangeLog,
      [dataKey]: { someValue: "some change" },
    });
    setVisitsAndAssessmentsMappingChangeLog({ someValue: "some change" });
  };

  const removeRow = (identifier) => {
    const filteredData = scheduleOfEventsAssessments[dataKey]?.filter(
      (sv) =>
        (isNumberNotEmpty(sv.scheduleOfEventsAssessmentId)
          ? sv.scheduleOfEventsAssessmentId
          : sv.key) !== identifier,
    );

    setScheduleOfEventsAssessments({
      ...scheduleOfEventsAssessments,
      [dataKey]: filteredData,
    });

    setScheduleOfEventsAssessmentsChangeLog({
      ...scheduleOfEventsAssessmentsChangeLog,
      [dataKey]: { someValue: "some change" },
    });
    setVisitsAndAssessmentsMappingChangeLog({ someValue: "some change" });
  };

  const handleAssessmentsValueChange = (name, value, key, type = "number") => {
    const currentData = scheduleOfEventsAssessments[dataKey] || [];

    const newData = currentData.map((sv) => {
      if ((sv.scheduleOfEventsAssessmentId || sv.key) === key) {
        if (name === "assessmentType") {
          return {
            ...sv,
            [name]: type === "number" && value ? value : value,
            assessmentNames: assessmentNames
              .filter((an) =>
                ASSESSMENT_TYPES_WITH_SITE_COST.includes(value)
                  ? an.assessmentType === "Site Costs"
                  : an.assessmentType === value,
              )
              .map((a) => a.assessmentName),
            assessmentNameMapping: "",
          };
        }
        return {
          ...sv,
          [name]: type === "number" && value ? value : value,
        };
      }
      return sv;
    });

    setScheduleOfEventsAssessments({
      ...scheduleOfEventsAssessments,
      [dataKey]: newData,
    });

    if (name !== "load") {
      setScheduleOfEventsAssessmentsChangeLog({
        ...scheduleOfEventsAssessmentsChangeLog,
        [dataKey]: { someValue: "some change" },
      });
    }
  };

  const onAssessmentsChange = (name, value, key, type) => {
    handleAssessmentsValueChange(name, value, key, type);
  };

  const renderTableRows = () => {
    return scheduleOfEventsAssessments[dataKey]?.map((sv, index) => (
      <TableRow
        fieldType={fieldType}
        removeColWidth={removeColWidth}
        typeColumnWidth={typeColumnWidth}
        key={sv.key || sv.scheduleOfEventsAssessmentId}
        sv={{ ...sv }}
        index={index}
        removeRow={removeRow}
        onAssessmentsChange={onAssessmentsChange}
        value={value}
        assessmentNames={sv.assessmentNames}
        assessments={scheduleOfEventsAssessments[dataKey]}
        columnCount={columnCount}
      />
    ));
  };

  const onDragEnd = (result) => {
    if (!result.destination) return;

    const currentData = [...(scheduleOfEventsAssessments[dataKey] || [])];
    const [removed] = currentData.splice(result.source.index, 1);
    currentData.splice(result.destination.index, 0, removed);

    setScheduleOfEventsAssessments({
      ...scheduleOfEventsAssessments,
      [dataKey]: currentData,
    });

    setScheduleOfEventsAssessmentsChangeLog({
      ...scheduleOfEventsAssessmentsChangeLog,
      [dataKey]: { someValue: "some change" },
    });
  };

  return (
    <>
      {!loadingCountrySOE && (
        <Accordion
          scrollIntoView={true}
          width="100%"
          summary="Assessments"
          defaultExpanded={tableVisibility.showAssessmentsTable}
          onClick={() => setTableVisibility("showAssessmentsTable")}
        >
          {tableVisibility.showAssessmentsTable && (
            <Container
              key={dataKey}
              onClick={(event) => event.stopPropagation()}
            >
              {!isCountry ? (
                <DragDropContext onDragEnd={onDragEnd}>
                  <Table>
                    <TableHeader
                      value={value}
                      columnCount={columnCount}
                      removeColWidth={removeColWidth}
                      typeColumnWidth={typeColumnWidth}
                      fieldType={fieldType}
                    />
                    <Droppable droppableId="assessments">
                      {(provided) => (
                        <RowsContainer
                          ref={provided.innerRef}
                          {...provided.droppableProps}
                        >
                          {renderTableRows()}
                          {provided.placeholder}
                        </RowsContainer>
                      )}
                    </Droppable>
                  </Table>
                </DragDropContext>
              ) : (
                <Table>
                  <TableHeader
                    columnCount={columnCount}
                    value={value}
                    removeColWidth={removeColWidth}
                    typeColumnWidth={typeColumnWidth}
                  />
                  {renderTableRows()}
                </Table>
              )}
              {value === 0 && (
                <ButtonContainer>
                  <Button
                    sx={{ width: "4rem" }}
                    onClick={addNewRow}
                    startIcon={<AddIcon />}
                  >
                    Add Row
                  </Button>
                </ButtonContainer>
              )}
            </Container>
          )}
        </Accordion>
      )}
    </>
  );
}

// Styled Components
const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const ButtonContainer = styled.div`
  margin: 1rem 0rem;
`;

const Table = styled.div`
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid var(--surface-300);
  width: 100%;
`;

const RowsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
