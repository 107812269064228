import { ConfigProvider, Divider, Input, Radio, Space } from "antd";
import { Close as CloseIcon } from "@material-ui/icons";
import { FIELD_TYPES } from "../constants";
import React from "react";
import { debounce } from "lodash";

export default function MultiplierInput({
  cellChecked,
  fieldType = "",
  onMultiplierChange,
  onUniqueChange,
  uniqueNonUnique: uniqueNonUniqueProp,
  multiplier: multiplierProp,
}) {
  const [multiplier, setMultiplier] = React.useState(multiplierProp ?? "");
  const [uniqueNonUnique, setUniqueNonUnique] = React.useState(
    uniqueNonUniqueProp || "",
  );

  const debouncedOnMultiplierChange = React.useCallback(
    debounce((value) => {
      onMultiplierChange(value);
    }, 1000),
    [],
  );

  const debouncedOnUniqueChange = React.useCallback(
    debounce((value) => {
      onUniqueChange(value);
    }, 1000),
  );

  React.useEffect(() => {
    if (!cellChecked && multiplier) {
      setMultiplier("");
      debouncedOnMultiplierChange("");
    }
  }, [cellChecked, debouncedOnMultiplierChange, multiplier]);

  const handleMultiplierChange = (event) => {
    const value = event.target.value.toString();
    if (value === "") {
      setMultiplier("");
      debouncedOnMultiplierChange("");
      return;
    }

    const regex = /^\d{1,3}(\.\d{0,2})?$/;
    if (regex.test(value)) {
      const numValue = parseFloat(value);
      if (numValue <= 100) {
        setMultiplier(value);
        debouncedOnMultiplierChange(value);
      }
    }
  };

  const handleUniqueNonUniqueChange = (event) => {
    setUniqueNonUnique(event.target.value);
    debouncedOnUniqueChange(event.target.value);
  };

  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: "#08979C",
        },
      }}
    >
      <div
        style={{
          display: "flex",
          gap: "5px",
          alignItems: "center",
          marginRight: "5px",
        }}
      >
        <CloseIcon style={{ width: "1rem", height: "1rem" }} />
        <div style={{ maxWidth: "50px" }}>
          <Input
            size="small"
            maxLength={6}
            value={multiplier}
            onChange={handleMultiplierChange}
            disabled={!cellChecked}
          />
        </div>
        {fieldType === FIELD_TYPES.DATA_MANAGEMENT && (
          <React.Fragment>
            <Divider type="vertical" />
            <div
              style={{
                border: "1px solid var(--surface-300)",
                paddingLeft: "5px",
                borderRadius: "5px",
              }}
            >
              <Radio.Group
                value={uniqueNonUnique}
                onChange={handleUniqueNonUniqueChange}
                size="small"
                disabled={!cellChecked}
              >
                <Space direction="horizontal">
                  <Radio value="U">U</Radio>
                  <Radio value="N">NU</Radio>
                </Space>
              </Radio.Group>
            </div>
          </React.Fragment>
        )}
      </div>
    </ConfigProvider>
  );
}
