import { IconButton, TextField } from "@material-ui/core";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import ClearIcon from "@material-ui/icons/Clear";
import CustomDatePickerRange from "../../../app/components/custom.date.picker.range";
import MuiTableHead from "@material-ui/core/TableHead";
import React from "react";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import { headCells } from "./constants";

// create a styled table cell with no horizontal padding
const StyledTableCell = withStyles((theme) => ({
  root: {
    padding: "5px",

    "&:first-child": {
      paddingLeft: "10px",
    },
  },
}))(TableCell);

const TableHead = ({ filters, setFilters, orderBy, order }) => {
  const classes = useStyles();

  const updateFilters = (id, value, type, startEnd) => {
    if (type === "date") {
      let prevValue = filters[id] || {
        id: id,
        type: "date",
      };
      if (startEnd === "start") {
        prevValue = {
          ...prevValue,
          start: value,
        };
      } else if (startEnd === "end") {
        prevValue = {
          ...prevValue,
          end: value,
        };
      }
      return setFilters({
        ...filters,
        [id]: prevValue,
      });
    }

    setFilters({
      ...filters,
      [id]: {
        ...filters[id],
        value: value,
        type: type,
      },
    });
  };

  const renderInputField = (id, type) => {
    if (type === "text")
      return (
        <TextField
          value={filters[id]?.value || ""}
          onChange={(e) => updateFilters(id, e.target.value, type)}
          size="small"
          variant="outlined"
          InputLabelProps={{ classes: classes }}
          fullWidth
        />
      );

    if (type === "date")
      return (
        <CustomDatePickerRange
          selected={filters[id]?.start}
          onChange={(dates) => {
            const [start, end] = dates;
            const startDate = start ? new Date(start) : null;
            const endDate = end ? new Date(end) : null;
            if (startDate && !isNaN(startDate.getTime())) {
              updateFilters(id, startDate.getTime(), type, "start");
            }

            if (endDate && !isNaN(endDate.getTime())) {
              updateFilters(id, endDate.getTime(), type, "end");
            }
          }}
          startDate={filters[id]?.start ? new Date(filters[id]?.start) : null}
          endDate={filters[id]?.end ? new Date(filters[id]?.end) : null}
          selectsRange
          value={
            filters[id]?.start && filters[id]?.end
              ? [new Date(filters[id]?.start), new Date(filters[id]?.end)]
              : [null, null]
          }
          showYearDropdown
          showMonthDropdown
          useShortMonthInDropdown
          dateFormat={"dd/MMM/yyyy"}
          small={true}
        />
      );
  };
  return (
    <MuiTableHead classes={classes}>
      <TableRow classes={classes}>
        <StyledTableCell>
          {Object.keys(filters).length > 0 && (
            <IconButton
              onClick={() => setFilters({})}
              variant="outlined"
              color="primary"
              size="small"
            >
              <ClearIcon />
            </IconButton>
          )}
        </StyledTableCell>
        {headCells.map(({ id, type }) => (
          <StyledTableCell
            style={["insertDate"].includes(id) ? { minWidth: "10rem" } : {}}
            key={id}
            align="center"
            padding="default"
            sortDirection={orderBy === id ? order : false}
          >
            {renderInputField(id, type)}
          </StyledTableCell>
        ))}
      </TableRow>
    </MuiTableHead>
  );
};

export default TableHead;

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "var(--surface-50)",
    borderRadius: "10px",
  },
}));
