import React, { useMemo, useEffect, useState } from "react";
import styled from "styled-components";
import Label from "../Label";

import { SpecialTextField, Tooltip } from "../../../../../../app/components";
import { useStudyPlan } from "../../../../../../app/contexts/study.context";

function toTitleCase(str) {
  return str.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
}

const ScenarioB = ({
  country,
  siteGroup,
  setHasErrors,
  values,
  setValues,
  setFieldErrors,
}) => {
  const { parameters, siteGroupId, milestones } = siteGroup;
  const { countryId } = country;
  const {
    expectedScreenFailureRate,
    expectedTreatmentDropoutRate,
    timeCountryStartUpSize,
    timeSiteIdSize,
  } = siteGroup;

  const { updateCountries } = useStudyPlan();
  const [sites_to_activate, subjects_to_screen] = parameters;
  const [
    number_patients_enrolled,
    number_patients_treatment,
    number_patients_follow_up,
  ] = milestones.filter(
    ({ milestoneId }) =>
      milestoneId === 144 || milestoneId === 147 || milestoneId === 150,
  );
  const [showPatientsScreenedError, setShowPatientsScreenedError] =
    useState(false);

  const isEmpty = (value) =>
    value === "" || value === null || value === undefined;

  const isPatientsScreenedLowerEmpty = isEmpty(subjects_to_screen.lowerBound);

  const handleUpdateSiteGroup = (property, value) => {
    const update = { [property]: value };
    updateCountries(countryId, siteGroupId, update);
  };

  const handleUpdateSiteGroupParameters = (param, property, value) => {
    let numericValue;
    if (value === "" || value === null || value === undefined) {
      numericValue = value;
    } else {
      numericValue = isNaN(value) ? value : Number(value);
    }

    const index = siteGroup.parameters
      .map((param) => param.parameterName)
      .indexOf(param.parameterName);
    const updated_param = { ...param, [property]: numericValue };
    const new_parameters = [...siteGroup.parameters];
    new_parameters[index] = updated_param;

    const update = { parameters: new_parameters };
    updateCountries(countryId, siteGroupId, update);
  };

  const handleUpdateSiteGroupMilestones = (milestone, property, value) => {
    const index = siteGroup.milestones
      .map((milestone) => milestone.milestoneName)
      .indexOf(milestone.milestoneName);
    const updated_milestone = { ...milestone, [property]: value };
    const new_milestones = [...siteGroup.milestones];
    new_milestones[index] = updated_milestone;

    const update = { milestones: new_milestones };
    updateCountries(countryId, siteGroupId, update);
  };

  const customValidationDefinition = useMemo(
    () => ({
      numberofactivatedsites: {
        range: [1, Infinity],
        validateEmpty: true,
        checkIfInt: true,
        checkIfDec: false,
      },
      numberofpatientsscreened: {
        range: [
          number_patients_follow_up.lowerBound ||
            number_patients_treatment.lowerBound ||
            number_patients_enrolled.lowerBound ||
            1,
          Infinity,
        ],
        validateEmpty: false,
        checkIfInt: true,
        checkIfDec: false,
      },

      "screenfailrate(indecimals)": {
        range: [0.0, 0.99],
        validateEmpty: false,
        checkIfInt: false,
        checkIfDec: true,
      },
      "dropoutrate(indecimals)": {
        range: [0.0, 0.99],
        validateEmpty: false,
        checkIfInt: false,
        checkIfDec: true,
      },
      numberofpatientsenrolled: {
        range: [
          number_patients_treatment.lowerBound ||
            number_patients_follow_up.lowerBound ||
            1,
          subjects_to_screen.lowerBound,
        ],
        validateEmpty: false,
        checkIfInt: true,
        checkIfDec: false,
      },
      numbrofpatientscompletingtreatment: {
        range: [
          number_patients_follow_up.lowerBound || 1,
          number_patients_enrolled.lowerBound || subjects_to_screen.lowerBound,
        ],
        validateEmpty: false,
        checkIfInt: true,
        checkIfDec: false,
      },
      numbrofpatientscompletingfollowup: {
        range: [
          1,
          number_patients_treatment.lowerBound ||
            number_patients_enrolled.lowerBound ||
            subjects_to_screen.lowerBound,
        ],
        validateEmpty: false,
        checkIfInt: true,
        checkIfDec: false,
      },
    }),
    [
      subjects_to_screen.lowerBound,
      number_patients_enrolled.lowerBound,
      number_patients_treatment.lowerBound,
      number_patients_follow_up.lowerBound,
    ],
  );

  useEffect(() => {
    const { lowerBound: numberPatientsFollowUp } = number_patients_follow_up;
    const { lowerBound: numberPatientsEnrolled } = number_patients_enrolled;
    const { lowerBound: numberOfPatientsScreened } = number_patients_treatment;

    const isPatientsScreenedEmpty = !isEmpty(numberOfPatientsScreened);
    const isPatientsEnrolledEmpty = !isEmpty(numberPatientsEnrolled);
    const isPatientsFollowUpEmpty = !isEmpty(numberPatientsFollowUp);

    const hasError =
      isPatientsFollowUpEmpty ||
      isPatientsEnrolledEmpty ||
      isPatientsScreenedEmpty;

    setShowPatientsScreenedError(hasError);

    setFieldErrors((prevState) => ({
      ...prevState,
      ...(hasError
        ? {
            numberPatientsFollowUpError: {
              ...prevState.numberPatientsFollowUpError,
              errors: ["Can't be empty."],
            },
          }
        : {
            numberPatientsFollowUpError: undefined,
          }),
    }));
  }, [
    number_patients_follow_up.lowerBound,
    number_patients_enrolled.lowerBound,
    number_patients_treatment.lowerBound,
    setFieldErrors,
  ]);

  useEffect(() => {
    const newErrors =
      showPatientsScreenedError && isPatientsScreenedLowerEmpty
        ? ["Can't be empty."]
        : [];

    setHasErrors((prevErrors) => {
      if (newErrors.length === 0) {
        return prevErrors;
      }
      return newErrors;
    });
  }, [showPatientsScreenedError, isPatientsScreenedLowerEmpty]);

  return (
    <Container>
      <SideBySideCols>
        <Label
          value={timeSiteIdSize ?? "- - "}
          variant="secondary"
        >
          Site ID Effort {timeSiteIdSize && " - "}
        </Label>
        <Label
          value={timeCountryStartUpSize ?? "- - "}
          variant="secondary"
        >
          Country Start-Up Time Frame {timeCountryStartUpSize && " - "}
        </Label>
      </SideBySideCols>
      <SideBySideCols>
        <SpecialTextField
          setHasErrors={setHasErrors}
          showArrows={false}
          style={{ marginLeft: "0.5em" }}
          label={toTitleCase(sites_to_activate.parameterDescr)}
          value={sites_to_activate.lowerBound}
          customValidation={
            customValidationDefinition["numberofactivatedsites"]
          }
          onChange={(value) => {
            handleUpdateSiteGroupParameters(
              sites_to_activate,
              "lowerBound",
              value,
            );
            // trigger formic form update so that we can validate fields
            setValues({ ...values, updated: new Date().getTime() });
          }}
          key={sites_to_activate.parameterName}
          setFieldErrors={setFieldErrors}
          required
        />
        <SpecialTextField
          setHasErrors={setHasErrors}
          showArrows={false}
          showCustomError={
            showPatientsScreenedError && isPatientsScreenedLowerEmpty
          }
          customMessageError={"Can't be empty."}
          style={{ marginLeft: "0.5em" }}
          label={toTitleCase(subjects_to_screen.parameterDescr)}
          value={subjects_to_screen.lowerBound}
          customValidation={
            customValidationDefinition["numberofpatientsscreened"]
          }
          onChange={(value) =>
            handleUpdateSiteGroupParameters(
              subjects_to_screen,
              "lowerBound",
              value,
            )
          }
          required={showPatientsScreenedError}
          key={subjects_to_screen.parameterName}
          setFieldErrors={setFieldErrors}
          endadornment={
            <Tooltip info="This field is required if a specific rate (screen fail or dropout) or specific number of patients is needed in this country." />
          }
        />
      </SideBySideCols>
      <TwoColumnLayout>
        <FlexCol>
          <div
            style={{
              display: "flex",
              gap: "1em",
              background: "var(--surface-100)",
              padding: "0.5em",
              border: "1px solid #f0f0f0",
              borderRadius: "4px",
            }}
          >
            <div style={{ flex: 1 }}>
              <SpecialTextField
                setHasErrors={setHasErrors}
                showArrows={false}
                label={number_patients_enrolled.milestoneDescr}
                value={number_patients_enrolled.lowerBound}
                customValidation={
                  customValidationDefinition["numberofpatientsenrolled"]
                }
                onChange={(value) =>
                  handleUpdateSiteGroupMilestones(
                    number_patients_enrolled,
                    "lowerBound",
                    value,
                  )
                }
                disabled={typeof expectedScreenFailureRate === "number"}
                key={number_patients_enrolled.milestoneName}
                setFieldErrors={setFieldErrors}
              />
            </div>
            <div style={{ flex: 1, display: "flex", alignItems: "center" }}>
              <SpecialTextField
                setHasErrors={setHasErrors}
                showArrows={false}
                label="Screen Fail Rate (in decimals)"
                value={expectedScreenFailureRate}
                onChange={(value) =>
                  handleUpdateSiteGroup("expectedScreenFailureRate", value)
                }
                disabled={
                  typeof number_patients_enrolled.lowerBound === "number"
                }
                inputProps={{ step: 0.01 }}
                min={0.01}
                setFieldErrors={setFieldErrors}
                customValidation={
                  customValidationDefinition["screenfailrate(indecimals)"]
                }
              />
              <Tooltip info="If a specific rate or number of enrolled patients is needed in this country, complete either the Screen Fail Rate OR the Number of Patients Enrolled. You must also complete the Number of Patients Screened. If no specific country numbers are needed, skip this section." />
            </div>
          </div>
        </FlexCol>
        <FlexCol>
          <div
            style={{
              display: "flex",
              gap: "1em",
              background: "var(--surface-100)",
              padding: "0.5em",
              border: "1px solid #f0f0f0",
              borderRadius: "4px",
              marginLeft: "0.3em",
            }}
          >
            <div style={{ flex: 1 }}>
              <SpecialTextField
                setHasErrors={setHasErrors}
                showArrows={false}
                label={number_patients_treatment.milestoneDescr}
                value={number_patients_treatment.lowerBound}
                customValidation={
                  customValidationDefinition[
                    "numbrofpatientscompletingtreatment"
                  ]
                }
                onChange={(value) =>
                  handleUpdateSiteGroupMilestones(
                    number_patients_treatment,
                    "lowerBound",
                    value,
                  )
                }
                disabled={typeof expectedTreatmentDropoutRate === "number"}
                key={number_patients_treatment.milestoneName}
                setFieldErrors={setFieldErrors}
              />
            </div>
            <div style={{ flex: 1, display: "flex", alignItems: "center" }}>
              <SpecialTextField
                setHasErrors={setHasErrors}
                showArrows={false}
                label="Drop Out Rate (in decimals)"
                value={expectedTreatmentDropoutRate}
                customValidation={
                  customValidationDefinition["dropoutrate(indecimals)"]
                }
                onChange={(value) =>
                  handleUpdateSiteGroup("expectedTreatmentDropoutRate", value)
                }
                disabled={
                  typeof number_patients_treatment.lowerBound === "number"
                }
                setFieldErrors={setFieldErrors}
                inputProps={{ step: 0.01 }}
                min={0.01}
              />
              <Tooltip info="If a specific rate or number of completed patients is needed in a country, complete either the Drop Out Rate OR the Number of Patients Completing Treatment. You must also complete either the Number of Patients Screened and either the Number of Patients Enrolled or the Screen Fail Rate. If no specific country numbers are needed, skip this section." />
            </div>
          </div>
        </FlexCol>
      </TwoColumnLayout>

      <SideBySideRows>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            paddingLeft: "1em",
          }}
        >
          <SpecialTextField
            setHasErrors={setHasErrors}
            style={{ marginLeft: "0.5em" }}
            showArrows={false}
            label={number_patients_follow_up.milestoneDescr}
            value={number_patients_follow_up.lowerBound}
            customValidation={
              customValidationDefinition["numbrofpatientscompletingfollowup"]
            }
            onChange={(value) =>
              handleUpdateSiteGroupMilestones(
                number_patients_follow_up,
                "lowerBound",
                value,
              )
            }
            key={number_patients_follow_up.milestoneName}
            setFieldErrors={setFieldErrors}
            endadornment={
              <Tooltip info="Only complete this field if it is applicable to the protocol and a specific number of patients is needed in this country. To use this field, you must also complete the other fields in this section. " />
            }
          />
        </div>
      </SideBySideRows>
    </Container>
  );
};

export default ScenarioB;

const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 1em;
`;

const SideBySideRows = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1em;
`;

const SideBySideCols = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 2em;
  grid-auto-flow: column;
  padding-left: 1em;
`;

const FlexCol = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 0.5em;
  margin-left: 1em;
`;
const TwoColumnLayout = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 0.5em;
`;
