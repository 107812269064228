import React, { useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import Typography from "@material-ui/core/Typography";
import styled from "styled-components";
import { useFavorite } from "../../app/contexts/favorites.context";
import { useUser } from "../../app/contexts/user.context";
import LinkIcon from "@material-ui/icons/Link";
import { SectionHeader } from "../../app/components";
import { LINKS } from "./constants";
import { makeStyles } from "@material-ui/core/styles";

const FavoriteStudy = ({ height }) => {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const { fetchFavorites, favorites } = useFavorite();
  const { user } = useUser();

  useEffect(() => {
    fetchFavorites(user?.userId);
    // eslint-disable-next-line
  }, []);

  // paramaterizing 'study plans', 'study actuals' part of URL - TODO: useParams() to replace this?
  const currTab = location.pathname.split("/").slice(2, 3);
  const handleClick = (favorite) =>
    favorite && history.push(`/ctpo/${currTab}/${favorite.studyId}`);

  const renderFavorites = () => {
    if (favorites.length === 0) {
      return <Typography color="textSecondary">No favorities yet.</Typography>;
    } else {
      return (
        <List>
          {favorites.map((favorite) => (
            <Item
              key={favorite.studyId}
              onClick={() => handleClick(favorite)}
            >
              <Typography
                style={{ marginBlockEnd: "0.3em" }}
                className={classes.hoverText}
                noWrap
              >
                {favorite.projectId} - {favorite.studyName}
              </Typography>
            </Item>
          ))}
        </List>
      );
    }
  };

  return (
    <div style={{ overflow: "hidden" }}>
      <div>
        <SectionHeader
          marginBottom={false}
          paddingBottom={false}
        >
          My Favorites
        </SectionHeader>
        <div style={{ overflowY: "auto", height }}>{renderFavorites()}</div>

        <div>
          <SectionHeader
            paddingBottom={false}
            marginBottom={false}
          >
            Quick Links
          </SectionHeader>

          {LINKS.map(({ href, text }) => (
            <QuickLink
              key={href}
              href={href}
              target="_blank"
              rel="noopener noreferrer"
            >
              <LinkIcon style={{ marginRight: "0.5rem" }} />
              <Typography
                style={{ width: "100%", height: "100%", paddingBlock: "0.3em" }}
                className={classes.hoverText}
              >
                {text}
              </Typography>
            </QuickLink>
          ))}
        </div>
      </div>
    </div>
  );
};
export default FavoriteStudy;

const Item = styled.div`
  margin-bottom: 0.5em;
  cursor: pointer;
  text-decoration: underline;
  color: var(--teal-700);
  &:hover {
    font-weight: bold;
  }
  &:active {
    color: var(--White);
  }
  &:focus-visible {
    outline: "1px solid var(--teal-600)",
    outlineOffset: "4px",
  }
  &:disabled {
    opacity: 0.6;
    cursor: "not-allowed";
  }  
`;

const List = styled.div``;

const QuickLink = styled.a`
  text-decoration: underline;
  color: var(--teal-700);
  cursor: pointer;
  display: flex;
  align-items: center;
  &:hover {
    font-weight: bold;
  }
  &:active {
    color: var(--White);
  }
  &:focus-visible {
    outline: "1px solid var(--teal-600)",
    outlineOffset: "4px",
  }
  &:disabled {
    opacity: 0.6;
    cursor: "not-allowed";
  }  
`;
const useStyles = makeStyles((theme) => ({
  hoverText: {
    "&:hover": {
      fontWeight: "bold",
    },
  },
}));
