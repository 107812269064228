export const regionColors = ["#66B5FF", "#339CFF", "#1890FF", "#096DD9"];

export const timelineColors = [
  "#1890FF",
  "#FA8C16",
  "#722ED1",
  "#13C2C2",
  "#FADB14",
  "#EB2F96",
  "#52C41A",
  "#E31636",
  "#E31636",
  "#1D5BBF",
];

export const siteGrpColors = [
  "#66B5FF",
  "#339CFF",
  "#1890FF",
  "#096DD9",
  "#004F99",
  "#003566",
  "#001A33",
  "#66B5FF",
  "#339CFF",
  "#1890FF",
  "#096DD9",
  "#004F99",
  "#003566",
  "#001A33",
  "#66B5FF",
  "#339CFF",
  "#1890FF",
  "#096DD9",
  "#004F99",
  "#003566",
  "#001A33",
  "#66B5FF",
  "#339CFF",
  "#1890FF",
  "#096DD9",
  "#004F99",
  "#003566",
  "#001A33",
  "#66B5FF",
  "#339CFF",
  "#1890FF",
  "#096DD9",
  "#004F99",
  "#003566",
  "#001A33",
  "#66B5FF",
  "#339CFF",
  "#1890FF",
  "#096DD9",
  "#004F99",
  "#003566",
  "#001A33",
  "#66B5FF",
  "#339CFF",
  "#1890FF",
  "#096DD9",
  "#004F99",
];
