import React, { useEffect, useState } from "react";
import { useOktaAuth } from "@okta/okta-react";
import DataBox from "../admin.data/sitegroup.details/data.box";
import styled from "styled-components";
import { SectionHeader } from "../app/components";
import Divider from "@material-ui/core/Divider";
import { useUser } from "../app/contexts/user.context";
import SeparateContainer from "../app/components/separate.Container";
import { CircularProgress } from "@material-ui/core";
import SideBySide from "../app/components/sideBySide";

const UserProfile = () => {
  const { authState, oktaAuth } = useOktaAuth();
  const { user } = useUser();
  const userGroups = user?.groups;
  const [userInfo, setUserInfo] = useState(null);

  const filteredGroups = userGroups
    .filter((group) => group.toLowerCase().includes("premierpredict"))
    .sort((a, b) => a.localeCompare(b));

  useEffect(() => {
    const fetchUserInfo = async () => {
      if (authState.isAuthenticated) {
        const user = await oktaAuth.getUser();
        setUserInfo(user);
      }
    };
    fetchUserInfo();
  }, [authState, oktaAuth]);

  const userFields = {
    "section 1": [
      { title: "First Name", value: userInfo?.given_name },
      { title: "Last Name", value: userInfo?.family_name },
    ],
    "section 2": [
      { title: "Email", value: userInfo?.email },
      { title: "Username", value: userInfo?.preferred_username },
    ],
    "section 3": [
      { title: "User ID", value: userInfo?.sub },
      { title: "Locale", value: userInfo?.locale },
    ],
  };

  if (userInfo === null) {
    return (
      <LoaderContainer>
        <CircularProgress />
      </LoaderContainer>
    );
  }

  return (
    <Container>
      <Header>
        <SectionHeader
          hasStartAdornment={false}
          marginBottom={false}
          useBackground={false}
        >
          {userInfo?.name}
        </SectionHeader>
      </Header>

      <Divider />

      {Object.entries(userFields).map(([section, fields], sectionIndex) => (
        <div key={sectionIndex}>
          <GridByTwo>
            {fields.map((field, index) => (
              <DataBox
                key={index}
                title={field.title}
                content={<h3>{field.value ?? ""}</h3>}
              />
            ))}
          </GridByTwo>
        </div>
      ))}

      <SeparateContainer>
        <SideBySide>
          <SectionHeader
            hasStartAdornment={false}
            marginBottom={false}
          >
            Groups
          </SectionHeader>
        </SideBySide>
        <Table>
          <Column>
            {filteredGroups.map((group, index) => (
              <Cell key={index}>
                <span>{group}</span>
              </Cell>
            ))}
          </Column>
        </Table>
      </SeparateContainer>
    </Container>
  );
};

export default UserProfile;

const Container = styled.div`
  overflow: auto;
  height: calc(100vh - 91px);
  background-color: white;
  margin: 1em;
  padding: 1em 2em;
`;

const Header = styled.div`
  display: flex;
  margin-bottom: 1em;
`;

const GridByTwo = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 2em;
  margin-top: 1em;
  margin-bottom: 2em;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
`;

const Cell = styled.div`
  background: ${(p) =>
    p.variant === "header" ? "var(--surface-100)" : "var(--White)"};
  height: 50px;
  padding-inline: ${(p) => (p.variant === "header" ? "0" : "1em")};
  align-items: center;
  justify-content: center;
  display: flex;
  border-bottom: 1px solid var(--surface-300);
  &:last-child {
    border: none;
  }
`;

const Table = styled.div`
  border: 1px solid var(--surface-300);
  border-radius: 4px;
  margin: 1em;
`;

const LoaderContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
`;
