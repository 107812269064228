import React from "react";
import OutlinedButton from "../../../app/components/buttons/outlined";

export const CloseButton = ({ setRemodelViewOpen }) => {
  return (
    <OutlinedButton
      onClick={() => setRemodelViewOpen(false)}
      style={{ width: "fit-content", padding: "5px" }}
    >
      Close
    </OutlinedButton>
  );
};
