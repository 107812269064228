import React from "react";
import predictLogo from "../../../images/predictLogo.webp";

const Logo = () => (
  <img
    src={predictLogo}
    alt="Remarque Logo"
    height="51px"
  />
);

export default Logo;
