import { CircularProgress, LinearProgress } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { Modal } from "../../../../app/components";
import { nanoid } from "nanoid";
import styled from "styled-components";
import { useScheduleEvents } from "../../../../app/contexts/schedule.events.context";
import { EditScheduleEventForm } from "./edit.schedule.events.form";
import PrimaryButton from "../../../../app/components/buttons/primary";
import OutlinedButton from "../../../../app/components/buttons/outlined";

const EditScheduleOfEventModal = ({ soe, ...props }) => {
  const [reRenderForm, setReRenderForm] = useState(nanoid());
  const { scheduleOfEvents, fetchASingleSOE, savingSOEStudy } =
    useScheduleEvents();

  const [SOEEventParameters, setSOEEventParameters] = useState({
    scheduleOfEventsName: soe?.scheduleOfEventsName,
    scheduleOfEventsDescr: soe?.scheduleOfEventsDescr,
    scheduleOfEventsStatus: soe?.scheduleOfEventsStatus,
    studyPlanId: soe?.studyPlanId,
    visitIntervalType: soe?.visitIntervalType,
    visitWindowIntervalType: soe?.visitWindowIntervalType,
    CDAReviewPctTime: soe?.CDAReviewPctTime,
    assessorName: soe?.assessorName,
    AddlTimeOnSitePct: soe?.AddlTimeOnSitePct,
    EstActivatedNonEnrollingSitesPct: soe.EstActivatedNonEnrollingSitesPct,
    ReqAdminIMVNonEnrollingSites: soe.ReqAdminIMVNonEnrollingSites,
    SubjectReportingAvgFrequency: soe.SubjectReportingAvgFrequency,
    SubjectReportingHoursToGenerate: soe.SubjectReportingHoursToGenerate,
    StudyReportingAvgFrequency: soe.StudyReportingAvgFrequency,
    StudyReportingHoursToGenerate: soe.StudyReportingHoursToGenerate,
    StudyAdhocReview: soe.StudyAdhocReview,
    protocolVersion: soe?.protocolVersion,
    protocolDate: soe?.protocolDate,
  });

  const [isProtocolVersionNA, setIsProtocolVersionNA] = useState(false);
  const [isProtocolDateNA, setIsProtocolDateNA] = useState(false);
  const [formValid, setFormValid] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [formTouched, setFormTouched] = useState(false);
  const [errors, setErrors] = useState({
    scheduleOfEventsName: "",
    scheduleOfEventsDescr: "",
    visitIntervalType: "",
    visitWindowIntervalType: "",
    CDAReviewPctTime: "",
    protocolVersion: "",
    protocolDate: "",
  });

  // Effect to fetch data
  useEffect(() => {
    const { scheduleOfEventsId } = soe;
    if (scheduleOfEventsId) {
      (async () => {
        setIsLoading(true);
        const res = await fetchASingleSOE(scheduleOfEventsId);
        if (res.data && res.data.length) {
          const data = res.data[0];
          setSOEEventParameters(data);
          setIsProtocolVersionNA(!data.protocolVersion);
          setIsProtocolDateNA(!data.protocolDate);
          setTimeout(() => {
            setReRenderForm(nanoid());
            setIsLoading(false);
          }, 400);
        } else setIsLoading(true);
      })();
    }
  }, []);

  // Debug effect
  useEffect(() => {
    console.log("Form Values:", {
      protocolVersion: SOEEventParameters.protocolVersion,
      protocolDate: SOEEventParameters.protocolDate,
      isProtocolVersionNA,
      isProtocolDateNA,
    });
  }, [SOEEventParameters, isProtocolVersionNA, isProtocolDateNA]);

  // Form validation effect
  useEffect(() => {
    if (
      SOEEventParameters["scheduleOfEventsDescr"] &&
      SOEEventParameters["scheduleOfEventsName"] &&
      SOEEventParameters["visitIntervalType"] &&
      SOEEventParameters["visitWindowIntervalType"] &&
      SOEEventParameters["CDAReviewPctTime"] &&
      (!isProtocolVersionNA ? SOEEventParameters["protocolVersion"] : true) &&
      (!isProtocolDateNA ? SOEEventParameters["protocolDate"] : true) &&
      !errors["scheduleOfEventsDescr"] &&
      !errors["scheduleOfEventsName"] &&
      !errors["visitIntervalType"] &&
      !errors["visitWindowIntervalType"] &&
      !errors["CDAReviewPctTime"] &&
      (isProtocolVersionNA ? true : !errors["protocolVersion"]) &&
      (isProtocolDateNA ? true : !errors["protocolDate"])
    ) {
      setFormValid(true);
    } else {
      setFormValid(false);
    }
  }, [SOEEventParameters, errors, isProtocolVersionNA, isProtocolDateNA]);

  const handleChange = (key, value) => {
    setFormTouched(true);
    setSOEEventParameters({ ...SOEEventParameters, [key]: value });
    if (!value && key !== "StudyPlanId") {
      setErrors({ ...errors, [key]: "This field is required" });
    } else {
      setErrors({ ...errors, [key]: "" });
    }
    if (key === "scheduleOfEventsName") {
      scheduleOfEvents.forEach((sp) => {
        if (sp.scheduleOfEventsId === soe.scheduleOfEventsId) return;
        if (
          sp?.scheduleOfEventsName.toLowerCase().trim() ===
          value.toLowerCase().trim()
        ) {
          setErrors({
            ...errors,
            [key]: "Schedule of Events name must be unique",
          });
        }
        if (!value.replace(/\s/g, "").length) {
          setErrors({
            ...errors,
            [key]: "SOE name must contain at least 1 character",
          });
        }
      });
    }
    if (key === "scheduleOfEventsDescr") {
      if (!value.replace(/\s/g, "").length) {
        setErrors({
          ...errors,
          [key]: "SOE description must contain at least 1 character",
        });
      }
    }
  };

  return (
    <Modal
      size={"80%"}
      maxWidth={"1200px"}
      {...props}
      aria-labelledby="Edit Schedule of Events"
      aria-describedby="Create a new schedule of events"
      title="Edit Schedule of Events"
    >
      {isLoading && <LinearProgress />}
      {!isLoading && (
        <Container key={reRenderForm}>
          <div style={{ display: "flex", gap: "1rem" }}>
            <EditScheduleEventForm
              handleChange={handleChange}
              errors={errors}
              SOEEventParameters={SOEEventParameters}
              isProtocolVersionNA={isProtocolVersionNA}
              setIsProtocolVersionNA={setIsProtocolVersionNA}
              isProtocolDateNA={isProtocolDateNA}
              setIsProtocolDateNA={setIsProtocolDateNA}
            />
          </div>
          <ButtonContainer>
            <OutlinedButton onClick={props.onClose}>Cancel</OutlinedButton>
            <PrimaryButton
              disabled={savingSOEStudy || !formValid || !formTouched}
              onClick={() =>
                props.onContinue({ ...soe, ...SOEEventParameters })
              }
            >
              {savingSOEStudy && <CircularProgress size="1em" />}
              {!savingSOEStudy && "Save"}
            </PrimaryButton>
          </ButtonContainer>
        </Container>
      )}
    </Modal>
  );
};

export default EditScheduleOfEventModal;

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 2em;
  padding-inline: 1em;
  max-height: 75vh;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 10px;
  }
  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  &::-webkit-scrollbar-thumb {
    background: #888;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 1em;
  background-color: white;
  with: 100%;
  position: sticky;
  bottom: -13px;
  z-index: 10;
  padding: 1rem 0rem;
`;
