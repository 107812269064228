import React, { useMemo } from "react";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  makeStyles,
} from "@material-ui/core";
import Row from "../../simulation/tables/details.table/row";
import { isValidNumberStringValues } from "../../utils/helpers";
import styled from "styled-components";
import { useScheduleEvents } from "../../../../app/contexts/schedule.events.context";
import SeparateContainer from "../../../../app/components/separate.Container";
import SectionHeader from "../../../../app/components/section.header";
import SideBySide from "../../../../app/components/sideBySide";

const COLUMN_SORT_ORDER = [
  "jan",
  "feb",
  "mar",
  "apr",
  "may",
  "jun",
  "jul",
  "aug",
  "sep",
  "oct",
  "nov",
  "dec",
];

export default function DetailsTable({ details }) {
  const rowClasses = useRowStyles();
  const headerClasses = useHeaderStyles();
  const cellHeaderClasses = useCellHeaderStyles();
  const tableClasses = useTableStyles();
  const cellClasses = useCellStyles();
  const { setFormattedForecastDetail } = useScheduleEvents();

  const [countrySummaries, total, COLUMN_HEADERS] = useMemo(() => {
    const cDetails = {};
    let t = null;
    const chS = new Set();

    if (!details) return [cDetails, t];

    details.forEach((s) => {
      if (s.dataSet === "Total") {
        chS.add(s.monthYear);
      }
    });

    const ch = [...chS].sort((a, b) => {
      const [monthA, yearA] = a.split(" ");
      const [monthB, yearB] = b.split(" ");

      return (
        yearA - yearB ||
        COLUMN_SORT_ORDER.indexOf(monthA.toLowerCase()) -
          COLUMN_SORT_ORDER.indexOf(monthB.toLowerCase())
      );
    });

    const columnHeaderLength = ch.length;
    details.forEach((s) => {
      const {
        totalCost,
        visitCount,
        assessmentCount,
        visitCost,
        assessmentCost,
        excludeCost,
        siteCost,
        monthYear,
      } = s;

      const valueIndex = ch.indexOf(monthYear);
      if (s.siteGroupName) {
        if (!cDetails[s.siteGroupName])
          cDetails[s.siteGroupName] = {
            details: [
              {
                country_name: "Visit Count",
                data: new Array(columnHeaderLength).fill(0),
              },
              {
                country_name: "Assessment Count",
                data: new Array(columnHeaderLength).fill(0),
              },
              {
                country_name: "Visit Overhead",
                data: new Array(columnHeaderLength).fill(0),
              },
              {
                country_name: "Assessment Cost",
                data: new Array(columnHeaderLength).fill(0),
              },
              {
                country_name: "Exclude Cost",
                data: new Array(columnHeaderLength).fill(0),
              },
              {
                country_name: "Site Cost",
                data: new Array(columnHeaderLength).fill(0),
              },
            ],
            subTotal: new Array(columnHeaderLength).fill(0),
          };
        if (s.dataSet === "SubTotal") {
          cDetails[s.siteGroupName].subTotal[valueIndex] = totalCost;
          cDetails[s.siteGroupName].details[0].data[valueIndex] = visitCount;
          cDetails[s.siteGroupName].details[1].data[valueIndex] =
            assessmentCount;
          cDetails[s.siteGroupName].details[2].data[valueIndex] = visitCost;
          cDetails[s.siteGroupName].details[3].data[valueIndex] =
            assessmentCost;
          cDetails[s.siteGroupName].details[4].data[valueIndex] = excludeCost;
          cDetails[s.siteGroupName].details[5].data[valueIndex] = siteCost;
        }
      }
      if (s.dataSet === "Total") {
        if (!t) t = new Array(columnHeaderLength).fill(0);
        t[valueIndex] = totalCost;
      }
    });

    setFormattedForecastDetail({ cDetails, t, ch });
    return [cDetails, t, ch];
  }, [details, setFormattedForecastDetail]);

  const renderCountryTables = () => {
    return Object.keys(countrySummaries).map((country, index) => {
      return (
        <Row
          cellWidth={"160px"}
          name={country}
          row={{
            header_totals: countrySummaries[country].subTotal,
            sub_table_body: countrySummaries[country].details,
          }}
          subTableLength={COLUMN_HEADERS.length + 1}
        />
      );
    });
  };
  return (
    <Container>
      <SeparateContainer>
        <SideBySide>
          <SectionHeader marginBottom={false}>Cost Detail</SectionHeader>
        </SideBySide>
        <TableContainer
          style={{ padding: "10px 20px 20px 20px", width: "99%" }}
          component={Paper}
        >
          {Object.keys(countrySummaries).length ? (
            <Table
              aria-label="collapsible table"
              classes={tableClasses}
              style={{ border: "1px solid var(--surface-300)" }}
            >
              <TableHead classes={rowClasses}>
                <TableRow style={{ height: "112px" }}>
                  <TableCell classes={cellHeaderClasses}></TableCell>
                  {COLUMN_HEADERS?.map((header) => (
                    <TableCell
                      style={{ width: "102.35px" }}
                      key={header}
                      align="right"
                      classes={headerClasses}
                    >
                      {header}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {renderCountryTables()}
                <TableRow classes={rowClasses}>
                  <TableCell
                    classes={cellHeaderClasses}
                    component="th"
                    scope="row"
                  >
                    <div>Total</div>
                  </TableCell>
                  {total?.map((t, index) => (
                    <TableCell
                      key={`t-${index}`}
                      align="right"
                      classes={cellClasses}
                    >
                      {!isValidNumberStringValues(t) ? t : Math.trunc(t)}
                    </TableCell>
                  ))}
                </TableRow>
              </TableBody>
            </Table>
          ) : (
            "No Results Found"
          )}
        </TableContainer>
      </SeparateContainer>
    </Container>
  );
}

const Container = styled.div`
  overflow-x: auto;
`;

const useCellStyles = makeStyles(() => ({
  root: {
    color: "#333",
    textAlign: "center",
    whiteSpace: "nowrap",
    padding: "0px",
    width: "81.83px",
  },
}));

const useRowStyles = makeStyles((theme) => ({
  root: {
    background: "var(--surface-100)",
  },
}));

const useHeaderStyles = makeStyles(() => ({
  root: {
    fontWeight: 600,
    textAlign: "center",
    whiteSpace: "nowrap",
    transformOrigin: "50% 50%",
    transform: "rotate(270deg)",
    padding: "0px",
  },
}));

const useCellHeaderStyles = makeStyles(() => ({
  root: {
    // width: "201px",
    position: "sticky",
    left: 0,
    background: "var(--surface-100)",
    zIndex: 10,
  },
}));

const useTableStyles = makeStyles(() => ({
  root: {
    width: "max-content",
  },
}));
