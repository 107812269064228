import React, { useMemo } from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableContainer from "@material-ui/core/TableContainer";
import { makeStyles } from "@material-ui/styles";
import styled from "styled-components";
import { useScheduleEvents } from "../../../../app/contexts/schedule.events.context";
import SeparateContainer from "../../../../app/components/separate.Container";
import SectionHeader from "../../../../app/components/section.header";
import SideBySide from "../../../../app/components/sideBySide";

// const COLUMNS = {
//   "Country (siteGroupName)": "Country (siteGroupName)",
//   "Number of Sites": "Number of Sites",
//   "Screen Fails": "Screen Fails",
//   "Complete Treatment": "Complete Treatment",
//   "Drop-out of Treatment": "Drop-out of Treatment",
//   "Cost Patients Complete Follow-up": "Cost Patients Complete Follow-up",
// };

export default function SummaryRFPTable({ summary }) {
  const rotate_cells = useRotationStyles();
  const classes_rows = useRowStyles();
  const classes_cells = useCellStyles();
  const { setFormattedForecastRFPSummary } = useScheduleEvents();

  const [clinical, columns] = useMemo(() => {
    const cols = { Procedure: 0 };
    let index = 1;
    summary.forEach((s) => {
      if (cols[s.visitName] === undefined) {
        cols[s.visitName] = index;
        index++;
      }
    });
    const d = {};
    summary.forEach((s) => {
      if (!d[s.rowLabel]) {
        d[s.rowLabel] = new Array(Object.keys(cols).length).fill("");
        d[s.rowLabel][0] = s.rowLabel;
      }
    });

    summary.forEach((s) => {
      d[s.rowLabel][cols[s.visitName]] = s.visitValue;
    });

    setFormattedForecastRFPSummary({ columns: cols, data: d });
    const data = {};
    const FIELDS = [
      "100% SDR/SDV Time (minutes)",
      "CRA Sampled Visit",
      "Onsite SDR/SDV (minutes)",
      "CDA Review (minutes)",
    ];
    Object.keys(d).forEach((key) => {
      if (FIELDS.includes(key)) {
        data[key] = d[key];
      }
    });
    return [data, cols];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [summary]);

  const [data, dataColumns] = useMemo(() => {
    const cols = { Procedure: 0 };
    let index = 1;
    summary.forEach((s) => {
      if (cols[s.visitName] === undefined) {
        cols[s.visitName] = index;
        index++;
      }
    });
    const d = {};
    summary.forEach((s) => {
      if (!d[s.rowLabel]) {
        d[s.rowLabel] = new Array(Object.keys(cols).length).fill("");
        d[s.rowLabel][0] = s.rowLabel;
      }
    });

    summary.forEach((s) => {
      d[s.rowLabel][cols[s.visitName]] = s.visitValue;
    });

    setFormattedForecastRFPSummary({ columns: cols, data: d });
    const dataManagement = {};
    const FIELDS = [
      "100% SDR/SDV Time (minutes)",
      "CRA Sampled Visit",
      "Onsite SDR/SDV (minutes)",
      "CDA Review (minutes)",
    ];
    Object.keys(d).forEach((key) => {
      if (!FIELDS.includes(key)) {
        dataManagement[key] = d[key];
      }
    });
    return [dataManagement, cols];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [summary]);

  const renderRow = (row) => {
    return row.map((cell, index) => {
      return index === 0 ? (
        <TableCell align="start">{cell}</TableCell>
      ) : (
        <TableCell align="center">{cell}</TableCell>
      );
    });
  };

  const renderTable = (title, data, columns, rotate_cells) => (
    <Container>
      <SeparateContainer>
        <SideBySide>
          <SectionHeader marginBottom={false}>{title}</SectionHeader>
        </SideBySide>
        <div style={{ padding: "10px 20px 20px 20px" }}>
          {Object.keys(data).length ? (
            <TableContainer style={{ width: "100%" }}>
              <Table
                aria-label="customized table"
                className="table-container"
                style={{ border: "1px solid var(--surface-300)" }}
              >
                <TableHead>
                  <TableRow
                    variant="head"
                    classes={classes_rows}
                  >
                    {Object.keys(columns).map((col, index) => (
                      <TableCell
                        key={col}
                        align={index === 0 ? "start" : "center"}
                        style={index === 0 ? { width: "20%" } : undefined}
                        classes={index === 0 ? classes_cells : rotate_cells}
                      >
                        <p
                          style={
                            index !== 0
                              ? { transform: "rotate(-180deg)" }
                              : undefined
                          }
                        >
                          {col}
                        </p>
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {Object.values(data).map((row, index) => (
                    <TableRow key={index}>{renderRow(row)}</TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          ) : (
            "No Results Found"
          )}
        </div>
      </SeparateContainer>
    </Container>
  );

  return (
    <>
      {renderTable("Clinical Strategy", clinical, columns, rotate_cells)}
      {renderTable("Data Management", data, dataColumns, rotate_cells)}
    </>
  );
}

const useRotationStyles = makeStyles((theme) => ({
  root: {
    writingMode: "vertical-rl",
    textOrientation: "mixed",
    fontWeight: 600,
  },
}));

const Container = styled.div`
  overflow-x: auto;
`;

const useRowStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "var(--surface-100)",
  },
}));

const useCellStyles = makeStyles((theme) => ({
  root: {
    fontWeight: 600,
  },
}));
