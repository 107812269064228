import React from "react";
import styled from "styled-components";

const SideBySide = (props) => {
  return <Container {...props}>{props.children}</Container>;
};

export default SideBySide;

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  background-color: var(--surface-50);
  border-radius: 4px;
`;
