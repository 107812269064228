import CDAStudyTable from "./cda.study.table";
import CDATable from "./cda.table";
import CRATable from "./cra.table";
import EPriceInfoTable from "./eprice.info.table";
import EPriceTable from "./eprice.table";
import IMVTable from "./imv.table";
import React from "react";
import VariablesTable from "./variables.table";
import styled from "styled-components";
import { useScheduleEvents } from "../../../../app/contexts/schedule.events.context";

export default function VisitSampleStrategyTables({ editOpen, setEditOpen }) {
  const { variablesData, ePrice, CRA, IMV, CDA, CDAStudy, ePriceInfo } =
    useScheduleEvents();
  return (
    <>
      <VariablesTable
        editOpen={editOpen}
        setEditOpen={setEditOpen}
        data={variablesData}
      />
      <EPriceTable data={ePrice} />
      <CRATable data={CRA} />
      <IMVTable data={IMV} />
      <CDATable
        data={CDA}
        setEditOpen={setEditOpen}
      />
      <CDAStudyTable
        data={CDAStudy}
        setEditOpen={setEditOpen}
      />
      <EPriceInfoTable data={ePriceInfo} />
    </>
  );
}

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  font-size: 14px;
`;

export const Row = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  border-bottom: ${(p) =>
    p.noBorder ? "none" : "1px solid var(--surface-300)"};
`;

export const Cell = styled.div`
  background: ${(p) =>
    p.variant === "header" ? "var(--surface-100)" : "white"};
  font-weight: ${(p) => (p.variant === "header" ? "bold" : "normal")};
  height: 100%;
  align-items: ${({ align }) => align || "center"};
  justify-content: ${({ justify }) => justify || "center"};
  display: flex;
  width: ${(p) => (p.width ? p.width : "100%")};
  border-right: 1px solid var(--surface-300);
  padding: 8px 4px;
  text-align: ${({ textAlign }) => textAlign || "center"};
  border-left: ${(p) =>
    p.leftBorder ? "1px solid var(--surface-300)" : "none"};
  &:last-child {
    border: ${(p) => (p.rightBorder ? "1px solid var(--surface-300)" : "none")};
  }
  border: ${(p) => (p.fullBorder ? "1px solid var(--surface-300)" : "")};
`;
