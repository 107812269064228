import Header from "./header";
import MuiModal from "@material-ui/core/Modal";
import React from "react";
import { makeStyles } from "@material-ui/core/styles";

const CustomModal = ({ sx, root = {}, ...props }) => {
  const useStyles = makeStyles((theme) => ({
    paper: {
      position: "absolute",
      width: props.size ? props.size : 500,
      maxWidth: props.maxWidth ? props.maxWidth : "none",
      border: "1px solid var(--surface-300) !important",
      boxShadow: theme.shadows[5],
      top: "calc(45%)",
      left: "calc(50%)",
      transform: "translate(-50%, -50%)",
      borderRadius: "4px",
      outline: "none",
      overflow: "hidden",
      ...sx,
    },
    root: {
      backgroundColor: theme.palette.background.paper,
      padding: "2em 1em",
      ...root,
    },
  }));
  const classes = useStyles();

  return (
    <MuiModal {...props}>
      <div className={classes.paper}>
        <Header
          text={props.title}
          onClose={props.onClose}
        />
        <div className={classes.root}>{props.children}</div>
      </div>
    </MuiModal>
  );
};

export default CustomModal;
