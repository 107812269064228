import React from "react";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/styles";

const DangerousButton = (props) => {
  const classes = useStyles();

  return (
    <Button
      className={classes.dangerousButton}
      {...props}
    >
      {props.children}
    </Button>
  );
};

export default DangerousButton;

const useStyles = makeStyles((theme) => ({
  dangerousButton: {
    color: "var(--White)",
    backgroundColor: "var(--red-600)",
    transition: "background-color 0.2s ease",
    fontWeight: "normal",
    padding: "8px 16px",
    "&:hover": {
      backgroundColor: "var(--red-700)",
    },
    "&:disabled": {
      opacity: 0.6,
      cursor: "not-allowed",
      color: "var(--White)",
    },
    "&:active": {
      backgroundColor: "var(--red-800)",
    },
    "&:focus": {
      backgroundColor: "var(--red-600)",
      outline: "1px solid var(--red-600)",
      outlineOffset: "4px",
    },
  },
}));
