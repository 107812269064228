import React from "react";
import styled from "styled-components";
import { useHistory, useParams } from "react-router-dom";
import { SectionHeader } from "../../../../../app/components";
import EditIcon from "@material-ui/icons/Edit";
import MilestoneItem from "./milestone.item";
import { useStudyPlan } from "../../../../../app/contexts/study.context";
import SeparateContainer from "../../../../../app/components/separate.Container";
import TextButton from "../../../../../app/components/buttons/text";
import SideBySide from "../../../../../app/components/sideBySide";

const CountryGlobalMilestones = ({ siteGroup, tab }) => {
  const { canEditPlan } = useStudyPlan();
  const history = useHistory();
  const { id, planId } = useParams();
  const { milestones } = siteGroup;
  const filteredMilestones = milestones.filter(
    (m) =>
      m.milestoneId === 158 ||
      m.milestoneId === 135 ||
      m.milestoneId === 136 ||
      m.milestoneId === 139 ||
      m.milestoneId === 142 ||
      m.milestoneId === 145 ||
      m.milestoneId === 148 ||
      m.milestoneId === 151 ||
      m.milestoneId === 146 ||
      m.milestoneId === 149 ||
      m.milestoneId === 152,
  );

  const leftSide = filteredMilestones.slice(0, 6);
  const rightSide = filteredMilestones.slice(7, 11);

  return (
    <SeparateContainer>
      <Container>
        <SideBySide>
          <SectionHeader paddingBottom={false}>
            Country Milestones
          </SectionHeader>
          {canEditPlan && (
            <TextButton
              style={{ marginBlock: "1em", marginInline: "0.6em" }}
              onClick={() =>
                history.push(
                  `/ctpo/study-plans/${id}/${planId}/countries-and-site-groups#country-milestones`,
                  { tabIndex: tab },
                )
              }
            >
              <EditIcon style={{ marginRight: ".3em" }} /> Edit
            </TextButton>
          )}
        </SideBySide>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            padding: "20px",
          }}
        >
          <Content>
            {leftSide.map((item) => (
              <MilestoneItem
                key={item.milestoneId}
                {...item}
              />
            ))}
          </Content>
          <Content>
            {rightSide.map((item) => (
              <MilestoneItem
                key={item.milestoneId}
                {...item}
              />
            ))}
          </Content>
        </div>
      </Container>
    </SeparateContainer>
  );
};

export default CountryGlobalMilestones;

const Container = styled.div`
  display: grid;
  grid-gap: 1em;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: left;
  width: 100%;
  margin-right: 1em;
  gap: 1em;
`;
