import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Controls from "../common/controls";
import Stepper from "../../common/stepper";
import PrimaryDetails from "./primary.details";
import OverallStudyInputs from "./overall.study.inputs";
import BottomControls from "./bottom.controls";
import { useParams } from "react-router-dom";
import { useUser } from "../../../app/contexts/user.context";
import { useStudyPlan } from "../../../app/contexts/study.context";
import { SectionHeader } from "../../../app/components";
import Typography from "@material-ui/core/Typography";
import SeparateContainer from "../../../app/components/separate.Container";
const OverallParameters = ({ values, errors, setValues, step }) => {
  const { id } = useParams();
  const { setURLStudyId } = useUser();
  const { studyPlan } = useStudyPlan();
  const [fieldErrors, setFieldErrors] = useState([]);
  const [scrolled, setScrolled] = useState(false);

  const handleScroll = () => {
    if (window.scrollY > 0) {
      setScrolled(true);
    } else {
      setScrolled(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  useEffect(() => {
    setURLStudyId(id);
    studyPlan["ActualStep"] = step;
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
    // trigger formic form update so that we can validate fields
    setValues({ ...values, updated: new Date().getTime() });
    // eslint-disable-next-line
  }, []);

  return (
    <Container>
      <ControlsContainer className={scrolled ? "scrolled" : ""}>
        <Controls
          errors={errors}
          endpoint={`/ctpo/study/${id}/EnrollmentPlanStep1`}
          fieldErrors={fieldErrors}
        />
      </ControlsContainer>
      <Stepper errors={errors} />
      <SeparateContainer>
        <div>
          <SideBySide>
            <SectionHeader paddingBottom={false}>
              Overall Study Inputs
            </SectionHeader>
          </SideBySide>
          <Typography style={{ marginBottom: "2em", paddingInline: "20px" }}>
            If certain parameters are not applicable to all countries, you can
            specify these parameters at the country level and skip them at this
            step. (<span style={{ color: "red" }}>*</span> = required field)
          </Typography>
        </div>
        <div style={{ paddingInline: "20px", paddingBottom: "20px" }}>
          <PrimaryDetails
            values={values}
            errors={errors}
            setValues={setValues}
          />
        </div>
      </SeparateContainer>
      <OverallStudyInputs
        values={values}
        errors={errors}
        setValues={setValues}
        setFieldErrors={setFieldErrors}
      />
      <BottomControls
        errors={errors}
        fieldErrors={fieldErrors}
      />
    </Container>
  );
};

export default OverallParameters;

const Container = styled.div`
  display: grid;
  grid-gap: 1em;
`;
const ControlsContainer = styled.div`
  position: sticky;
  top: 11em;
  left: 0;

  background: white;
  z-index: 5;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 1em;
  box-shadow: none;

  transition: box-shadow 0.4s ease-in-out;

  &.scrolled {
    box-shadow: 0 8px 12px rgba(0, 0, 0, 0.25);
  }
`;

const SideBySide = styled.div`
  display: flex;
  justify-content: space-between;
  background-color: var(--surface-50);
  border-radius: 4px;
  margin-bottom: 1em;
`;
