import React from "react";
import styled from "styled-components";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import ViewSelector from "./view.selector";
import Row from "./row";
import { makeStyles } from "@material-ui/core/styles";
import { useStudyPlan } from "../../../../../app/contexts/study.context";
import { Title } from "../../../../../app/components";

const rowsData = [
  { name: "Active Sites", key: "active_sites" },
  { name: "Screened", key: "screened" },
  { name: "Enrolled", key: "enrolled" },
  { name: "Completed", key: "completed" },
];

export default function DetailsTable({ view, setView, type, ...props }) {
  const rowClasses = useRowStyles();
  const headerClasses = useHeaderStyles();
  const cellHeaderClasses = useCellHeaderStyles();
  const { simulationResults } = useStudyPlan();
  const {
    detailsByWeek,
    detailsByWeekNonCumulative,
    detailsByMonth,
    detailsByMonthNonCumulative,
    detailsByWeekCohort,
    detailsByWeekNonCumulativeCohort,
    detailsByMonthCohort,
    detailsByMonthNonCumulativeCohort,
  } = simulationResults;

  let data = {
    monthly: type === "cohort" ? detailsByMonthCohort : detailsByMonth,
    weekly: type === "cohort" ? detailsByWeekCohort : detailsByWeek,
    monthlyNonCumulative:
      type === "cohort"
        ? detailsByMonthNonCumulativeCohort
        : detailsByMonthNonCumulative,
    weeklyNonCumulative:
      type === "cohort"
        ? detailsByWeekNonCumulativeCohort
        : detailsByWeekNonCumulative,
  };

  if (!data[view]) {
    return null;
  }

  const subTableLength = data[view].header_dates?.length + 1;

  return (
    <StyledDetailsTable>
      {
        <ControlsContainer>
          {props.extraControls &&
            React.cloneElement(props.extraControls, { detailsView: view })}
        </ControlsContainer>
      }
      <TitleContainer>
        <Title
          size="large"
          dense
        >
          {type === "cohort" ? "Details - Cohort" : "Details"}
        </Title>
      </TitleContainer>
      <TableContainer
        component={Paper}
        style={{ overflowX: "auto", maxWidth: "100%" }}
      >
        <Table aria-label="collapsible table">
          <TableHead classes={rowClasses}>
            <TableRow style={{ height: "112px" }}>
              <TableCell classes={cellHeaderClasses}></TableCell>
              {data[view].header_dates?.map((date) => (
                <TableCell
                  key={date}
                  align="right"
                  classes={headerClasses}
                >
                  {date}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rowsData.map(({ name, key }) => (
              <Row
                key={key}
                name={name}
                row={data[view]?.table_body?.[key]}
                subTableLength={subTableLength}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <ViewSelector
        type={type}
        value={view}
        onChange={(view) => setView(view)}
      />
    </StyledDetailsTable>
  );
}

const useRowStyles = makeStyles((theme) => ({
  root: {
    background: "var(--surface-100)",
  },
}));

const useHeaderStyles = makeStyles(() => ({
  root: {
    fontWeight: 600,
    textAlign: "center",
    whiteSpace: "nowrap",
    transformOrigin: "50% 50%",
    transform: "rotate(270deg)",
    padding: "10px",
    width: "80px",
    minWidth: "80px",
    maxWidth: "80px",
  },
}));

const useCellHeaderStyles = makeStyles(() => ({
  root: {
    width: "201px",
    position: "sticky",
    left: 0,
    background: "var(--surface-100)",
    zIndex: 1,
  },
}));

const ControlsContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const StyledDetailsTable = styled.div`
  border-top: 0.1px solid rgba(224, 224, 224, 0.25);
  border-left: 0.1px solid rgba(224, 224, 224, 0.5);
  border-right: 0.1px solid rgba(224, 224, 224, 0.5);
  border-bottom: 2px solid rgba(224, 224, 224, 1);
  overflow-x: auto;
  width: 100%;
  margin-top: 1em;
`;

const TitleContainer = styled.div`
  background-color: var(--surface-50);
  padding: 1em;
  border-radius: 4px;
`;
