import React from "react";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import { useHistory, useParams } from "react-router-dom";
import styled from "styled-components";
import TextButton from "../../../app/components/buttons/text";

export default function TopControls() {
  const { id } = useParams();
  const history = useHistory();

  const handleBackStudyActual = () => {
    history.push(`/ctpo/study-actuals/${id}`);
  };

  return (
    <Container>
      <TextButton onClick={handleBackStudyActual}>
        <ChevronLeftIcon />
        Back
      </TextButton>
    </Container>
  );
}

const Container = styled.div`
  margin-top: 1rem;
`;
