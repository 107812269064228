import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
} from "@material-ui/core";
import React from "react";
import styled from "styled-components";
import { SectionHeader } from "../../../../app/components";
import SpecialRadioButton from "../../../../app/components/special.radiobutton";
import moment from "moment";
import SeparateContainer from "../../../../app/components/separate.Container";
import SideBySide from "../../../../app/components/sideBySide";

export default function PatientEnrollmentDate({
  errors,
  touched,
  values,
  setValues,
}) {
  return (
    <Container>
      <SeparateContainer>
        <SideBySide>
          <SectionHeader
            hasStartAdornment={false}
            paddingBottom={false}
          >
            Last Patient Enrollment Date
          </SectionHeader>
        </SideBySide>
        <div style={{ padding: "10px 20px 20px 20px" }}>
          <FormControl
            style={{ width: "100%", zIndex: 100, position: "initial" }}
            component="fieldset"
          >
            <RadioGroup
              row
              aria-label="overallEnrollmentDurationSelection"
              name="overallEnrollmentDurationSelection"
              value={values.overallEnrollmentDurationSelection}
              onChange={(e) => {
                setValues({
                  ...values,
                  overallEnrollmentDurationSelection: parseFloat(
                    e.target.value,
                  ),
                });
              }}
            >
              <TableContainer>
                <Table>
                  <Row>
                    <Cell variant="header">
                      <Typography align="center">
                        <strong>Planned</strong>
                      </Typography>
                    </Cell>
                    <Cell>
                      <FormControlLabel
                        value={1}
                        control={<Radio color="primary" />}
                        label={moment(
                          values?.lastPatientEnrolledDateMillisPlanned,
                        )
                          .utc()
                          .format("DD/MMM/YYYY")}
                      />
                    </Cell>
                  </Row>
                </Table>
                <Table>
                  <Row>
                    <Cell variant="header">
                      <Typography align="center">
                        <strong>Other</strong>
                      </Typography>
                    </Cell>
                    <Cell>
                      <SpecialRadioButton
                        type="date"
                        currentRadioValue={2}
                        onChange={(value) =>
                          setValues({
                            ...values,
                            lastPatientEnrolledDateMillisOther: value,
                          })
                        }
                        value={values?.lastPatientEnrolledDateMillisOther}
                        error={!!errors?.lastPatientEnrolledDateMillisOther}
                      />
                    </Cell>
                  </Row>
                </Table>
              </TableContainer>
            </RadioGroup>
            {errors.overallEnrollment && (
              <ErrorMessageContainer className="MuiFormHelperText-root">
                {errors.overallEnrollment}
              </ErrorMessageContainer>
            )}
          </FormControl>
        </div>
      </SeparateContainer>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const TableContainer = styled.div`
  width: 100%;
  display: flex;
  column-gap: 1rem;
`;

const Row = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  border-bottom: 1px solid var(--surface-300);
  height: 10em;

  &:last-child {
    border-bottom: none;
  }
`;

const Cell = styled.div`
  background: ${(p) =>
    p.variant === "header" ? "var(--surface-100)" : "white"};
  height: 100%;
  align-items: center;
  justify-content: center;
  display: flex;
  border-bottom: 1px solid var(--surface-300);
  padding: 0.5em;
`;

const Table = styled.div`
  display: flex;
  flex: 1;
  border: 1px solid var(--surface-300);
  border-bottom: none;
  border-radius: 4px;
  max-width: 35%;
`;

const ErrorMessageContainer = styled.div`
  color: #f44336 !important;
  margin: 3px 14px !important;
`;
