import { LinearProgress } from "@material-ui/core";
import React, { useMemo, useState } from "react";
import API from "../../../../../src/app/utils/api";
import FileUploader from "../../../../app/components/file.uploader";
import CustomModal from "../../../../app/components/modal.no.close";
import styled from "styled-components";
import { useSnackbar } from "../../../../app/contexts/snackbar.context";
import { useUser } from "../../../../app/contexts/user.context";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import PrimaryButton from "../../../../app/components/buttons/primary";
import OutlinedButton from "../../../../app/components/buttons/outlined";

const UPLOAD_STEPS = {
  UPLOAD_FILE: "UPLOAD_FILE",
  ENTER_FILE_ATTRS: "ENTER_FILE_ATTRS",
  UPLOAD_PROTOCOL: "UPLOAD_PROTOCOL",
  UPLOAD_COMPLETE: "UPLOAD_COMPLETE", // Added new step for completion status
};

const UPLOAD_OPTION_LABELS = {
  BudgetVue: "Upload to BudgetVue",
  Predict: "Upload to Predict",
  BudgetVue_Predict: "Upload to BudgetVue & Predict",
};

const UploadSOEProtocolModal = ({ soe, fetchSOEValues, ...props }) => {
  const { showSnackbar } = useSnackbar();
  const user = useUser();
  const [progress, setProgress] = useState(100 / 60);
  const [status, setStatus] = useState("");
  const [files, setFiles] = useState([]);
  const [step, setStep] = useState("UPLOAD_FILE");
  const [optionSelected, setOptionSelected] = useState("");
  const [uploadFileStatus, setUploadFileStatus] = useState("");
  const [fileAttributes, setFileAttributes] = useState({
    pageNumbers: "",
    visitCategoryLabel: "",
    visitNameLabel: "",
    visitIntervalLabel: "",
    visitWindowLabel: "",
  });
  const [loading, setLoading] = useState(false);

  const handleChange = async (files) => {
    if (files.length === 0) return;
    setFiles(files);
  };

  const handleLabelsChange = async (e) => {
    const value = e.target.value;
    setOptionSelected(value);
  };

  const pageNumbersValid = useMemo(() => {
    const regex = /^(\d+,)*\d+$/;
    return (
      regex.test(fileAttributes.pageNumbers) ||
      fileAttributes.pageNumbers === ""
    );
  }, [fileAttributes.pageNumbers]);

  const handleUpload = async () => {
    if (files.length === 0) return;

    setLoading(true);

    try {
      const base64EncodedString = await files[0]
        .arrayBuffer()
        .then((buffer) => {
          const base64String = btoa(
            new Uint8Array(buffer).reduce(
              (data, byte) => data + String.fromCharCode(byte),
              "",
            ),
          );
          return base64String;
        });

      const attributeValues = {
        uploadType: optionSelected,
      };

      const payload = {
        FileType: "StudyProtocol",
        FileId: soe.scheduleOfEventsId,
        FileName: files[0].name,
        encodedData: base64EncodedString,
        FileAttrs: `{FileIdKey: 'scheduleOfEventsId',scheduleOfEventsId:${soe.scheduleOfEventsId},studyId:${soe.studyId},uploadType:${attributeValues.uploadType},pdfFields:[Version,ProtocolName,Start]}`,
        Description: "",
        InsertedBy: user.username,
      };

      await API.uploadProtocol(payload);

      const progressInterval = setInterval(() => {
        setProgress((oldProgress) => {
          try {
            API.getSOE(soe.scheduleOfEventsId).then((res) => {
              if (res.data.length) {
                const uploadStatus = res.data[0].uploadStatus;
                setStatus(uploadStatus);

                if (uploadStatus.length > 40) {
                  if (uploadStatus.toLowerCase().includes("failed")) {
                    showSnackbar("Failed to upload", "error");
                    clearInterval(progressInterval);
                    setUploadFileStatus("Error");
                    setLoading(false);
                    setStep(UPLOAD_STEPS.UPLOAD_COMPLETE);
                  } else {
                    showSnackbar("Uploaded successfully", "success");
                    setUploadFileStatus("Success");
                    clearInterval(progressInterval);
                    setLoading(false);
                    setStep(UPLOAD_STEPS.UPLOAD_COMPLETE);
                  }
                }
              }
            });
          } catch (err) {
            console.log(err);
          }
          return oldProgress;
        });
      }, 10000);
    } catch (error) {
      console.log(error);
      showSnackbar("Failed to upload", "error");
      setLoading(false);
      setStep(UPLOAD_STEPS.UPLOAD_COMPLETE);
    }
  };

  const handleContinue = () => {
    if (uploadFileStatus === "Success") {
      fetchSOEValues();
    }
    props.onClose();
  };

  const nextStep = () => {
    if (files.length === 0) return;
    const nextStepValue =
      step === UPLOAD_STEPS.UPLOAD_FILE
        ? UPLOAD_STEPS.ENTER_FILE_ATTRS
        : UPLOAD_STEPS.UPLOAD_PROTOCOL;
    setStep(nextStepValue);
    if (nextStepValue === UPLOAD_STEPS.UPLOAD_PROTOCOL) {
      handleUpload();
    }
  };

  const enableContinue =
    files.length === 0 ||
    (step === UPLOAD_STEPS.ENTER_FILE_ATTRS && !pageNumbersValid);

  return (
    <CustomModal
      size={700}
      {...props}
      onClose={step !== UPLOAD_STEPS.UPLOAD_PROTOCOL && props.onClose}
      aria-labelledby="Upload ePrice"
      aria-describedby="upload-ePrice"
      title="Upload ePrice"
    >
      <Container>
        {step === UPLOAD_STEPS.UPLOAD_FILE && (
          <VContainer>
            <FileUploader
              acceptedFiles={{
                "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
                  [],
              }}
              onFileUpload={(event) => handleChange(event)}
            />
          </VContainer>
        )}
        {step === UPLOAD_STEPS.ENTER_FILE_ATTRS && (
          <VContainer>
            <FormControl>
              <RadioGroup
                aria-label="view"
                name="view"
                row
                onChange={handleLabelsChange}
              >
                <div style={{ display: "flex", flexDirection: "column" }}>
                  {Object.entries(UPLOAD_OPTION_LABELS).map(
                    ([value, label]) => (
                      <FormControlLabel
                        key={value}
                        value={value}
                        control={<Radio />}
                        label={label}
                      />
                    ),
                  )}
                </div>
              </RadioGroup>
            </FormControl>
          </VContainer>
        )}
        {step === UPLOAD_STEPS.UPLOAD_PROTOCOL && loading && (
          <VContainer>
            <LinearProgress variant="indeterminate" />
            <div>{status || "Uploading and processing soe extracts..."}</div>
          </VContainer>
        )}
        {step === UPLOAD_STEPS.UPLOAD_COMPLETE && (
          <VContainer>
            <StatusContainer success={uploadFileStatus === "Success"}>
              <div className="mb-4">
                {uploadFileStatus === "Success"
                  ? "Upload completed successfully!"
                  : "Upload failed. Please try again."}
              </div>
              {status && <div className="text-sm">Status: {status}</div>}
            </StatusContainer>
            <ButtonContainer>
              <PrimaryButton onClick={handleContinue}>Continue</PrimaryButton>
            </ButtonContainer>
          </VContainer>
        )}
        {(step === UPLOAD_STEPS.UPLOAD_FILE ||
          step === UPLOAD_STEPS.ENTER_FILE_ATTRS) && (
          <ButtonContainer>
            <OutlinedButton onClick={props.onClose}>Cancel</OutlinedButton>
            <PrimaryButton
              onClick={nextStep}
              disabled={enableContinue}
            >
              Continue
            </PrimaryButton>
          </ButtonContainer>
        )}
      </Container>
    </CustomModal>
  );
};

export default UploadSOEProtocolModal;

const VContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 2em;
  padding-top: 0;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2em;
  padding: 1em;
  padding-bottom: 0em;
  padding-top: 0;
  max-height: 75vh;
  overflow-y: auto;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 1em;
`;

const StatusContainer = styled.div`
  padding: 1em;
  border-radius: 4px;
  text-align: center;
  background-color: ${(props) => (props.success ? "#e8f5e9" : "#ffebee")};
  color: ${(props) => (props.success ? "#2e7d32" : "#c62828")};
`;
