import React from "react";
import MuiAppBar from "@material-ui/core/AppBar";
import { makeStyles } from "@material-ui/core/styles";

const AppBar = ({ children }) => {
  const classes = useStyles();
  return (
    <MuiAppBar
      position="fixed"
      className={classes.appBar}
    >
      <div className={classes.root}>{children}</div>
    </MuiAppBar>
  );
};

export default AppBar;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "space-between",
    height: "100%", // Change this to 100% to fill the entire AppBar height
    padding: "0 2em",
    // Remove the margin
  },
  appBar: {
    background: "linear-gradient(to right, var(--teal-700), var(--teal-500))",
    color: "var(--White)",
    height: "61px", // Set the height of the AppBar to 61px
  },
}));
