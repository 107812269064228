import React from "react";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import MuiTableHead from "@material-ui/core/TableHead";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { IconButton } from "@material-ui/core";
import FilterListIcon from "@material-ui/icons/FilterList";
import { headCells } from "./constants";
const StyledTableCell = withStyles((theme) => ({
  root: {
    padding: "5px",

    "&:first-child": {
      paddingLeft: "10px",
    },
  },
}))(TableCell);

const TableHead = ({
  filterActive,
  setFilterActive,
  orderBy,
  order,
  onRequestSort,
}) => {
  const classes = useStyles();
  const otherClasses = useOtherStyles();
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <MuiTableHead classes={classes}>
      <TableRow classes={classes}>
        <StyledTableCell>
          <IconButton onClick={() => setFilterActive(!filterActive)}>
            <FilterListIcon fontSize="small" />
          </IconButton>
        </StyledTableCell>
        {headCells.map(({ id, label, minWidth, numeric }) => (
          <StyledTableCell
            key={id}
            padding="default"
            sortDirection={orderBy === id ? order : false}
            style={{ minWidth }}
            align="left"
          >
            <TableSortLabel
              active={orderBy === id}
              direction={orderBy === id ? order : "asc"}
              onClick={createSortHandler(id)}
            >
              <strong>{label}</strong>
              {orderBy === id && (
                <span className={otherClasses.visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </span>
              )}
            </TableSortLabel>
          </StyledTableCell>
        ))}
      </TableRow>
    </MuiTableHead>
  );
};

export default TableHead;

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "var(--surface-100)",
    borderRadius: "10px",
  },
}));

const useOtherStyles = makeStyles((theme) => ({
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
}));
