import React from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import Modal from "../../modal";
import Typography from "@material-ui/core/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useStudyPlan } from "../../../contexts/study.context";
import OutlinedButton from "../../buttons/outlined";
import DangerousButton from "../../buttons/dangerous";

const DeleteCountryModal = ({
  countryId,
  siteGroup,
  open = false,
  onClose,
}) => {
  const history = useHistory();
  const { removeCountry, deleteCountryLoading } = useStudyPlan();

  const handleDelete = async () => {
    const body = {
      studyPlanId: siteGroup.studyPlanId,
      siteGroupId: siteGroup.siteGroupId,
    };

    try {
      // Wait for removeCountry to complete
      await new Promise((resolve, reject) => {
        removeCountry(body, siteGroup.studyId, countryId, () => {
          onClose();
          resolve();
        });
      });

      // Only navigate and reload after removeCountry is done
      history.push(
        `/ctpo/study-plans/${siteGroup.studyId}/${siteGroup.studyPlanId}/countries-and-site-groups?country=0`,
      );
      window.location.reload();
    } catch (error) {
      console.error("Error deleting country:", error);
    }
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="Delete country"
      aria-describedby="Delete a country"
      title="Delete Country"
    >
      <Container>
        <Typography>
          Are you sure you want to remove this country from your study plan?
          This action cannot be undone and any changed data will be lost.
        </Typography>

        <ButtonContainer>
          <OutlinedButton
            onClick={onClose}
            disabled={deleteCountryLoading}
          >
            Cancel
          </OutlinedButton>
          <DangerousButton
            onClick={handleDelete}
            disabled={deleteCountryLoading}
          >
            {deleteCountryLoading ? (
              <CircularProgress size="1em" />
            ) : (
              "Remove Country"
            )}
          </DangerousButton>
        </ButtonContainer>
      </Container>
    </Modal>
  );
};

export default DeleteCountryModal;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 1em;
`;

const Container = styled.div`
  display: grid;
  grid-gap: 2em;
`;
