import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import API from "../../../../../../app/utils/api";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import Divider from "@material-ui/core/Divider";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import { Modal, SpecialTextField } from "../../../../../../app/components";
import Select from "@material-ui/core/Select";
import Typography from "@material-ui/core/Typography";
import styled from "styled-components";
import { useSnackbar } from "../../../../../../app/contexts/snackbar.context";
import { useStudyPlan } from "../../../../../../app/contexts/study.context";
import ToolTip from "../../../../../../app/components/tooltip";
import Label from "../../../country.panel/country.inputs/Label";
import { makeStyles } from "@material-ui/core/styles";
import OutlinedButton from "../../../../../../app/components/buttons/outlined";
import PrimaryButton from "../../../../../../app/components/buttons/primary";

const timeFrames = [
  { value: "Super Short", key: "timeCountryStartUpSuperShortDays" },
  { value: "Short", key: "timeCountryStartUpShortDays" },
  { value: "Medium", key: "timeCountryStartUpMediumDays" },
  { value: "Long", key: "timeCountryStartUpLongDays" },
];

const getDaysAndMonths = (days) => {
  const months = (days / 30.42).toFixed(2);
  return `${days}; ${months} months`;
};

const AddCountryModal = ({ open = false, onClose }) => {
  const { id, planId } = useParams();
  const history = useHistory();
  const [siteGroups, setSiteGroups] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [selectedEffort, setSelectedEffort] = useState(null);
  const [selectedTimeframe, setSelectedTimeframe] = useState(null);
  const [sites_to_activateLower, setSites_to_activateLower] = useState("");
  const [sites_to_activateUpper, setSites_to_activateUpper] = useState("");
  const { showSnackbar } = useSnackbar();
  const { studyPlan } = useStudyPlan();

  useEffect(() => {
    API.getSiteGroups()
      .then((res) => {
        setSiteGroups(res.data);
      })
      .catch((err) => console.error(err));
  }, []);

  const handleSave = () => {
    const canAdd = studyPlan.countries
      .map((country) => country.siteGroups[0].siteGroupId)
      .indexOf(selectedCountry.siteGroupId);

    if (canAdd === -1) {
      const body = {
        studyPlanId: studyPlan.studyPlanId,
        siteGroupId: selectedCountry.siteGroupId,
        NUM_SITES_ACTIVATED_LOWER: sites_to_activateLower,
        NUM_SITES_ACTIVATED_UPPER: sites_to_activateUpper,
        siteIdEffort: selectedEffort,
        countryStartUpTimeFrame: selectedTimeframe,
      };

      setLoading(true);
      API.postSiteGroup(body, studyPlan.studyId)
        .then((res) => {
          setLoading(false);
          setSelectedCountry(null);
          setSelectedEffort(null);
          setSelectedTimeframe(null);
          onClose();
          history.push(
            `/ctpo/study-plans/${id}/${planId}/countries-and-site-groups?country=${res.data[0].tabIndex}`,
          );
          setTimeout(() => {
            window.location.reload();
          }, 0);
        })
        .catch((error) => {
          setLoading(false);
        });
    } else {
      showSnackbar("Country already added.", "error");
    }
  };

  const handleSelectCountry = (event) => {
    setSelectedCountry(event.target.value);
    setSelectedEffort(null);
    setSelectedTimeframe(null);
    setSites_to_activateLower("");
    setSites_to_activateUpper("");
  };

  const handleUpdateSiteGroupParameters = (param, property, value) => {
    if (property === "lowerBound") {
      setSites_to_activateLower(value);
    }
    if (property === "upperBound") {
      setSites_to_activateUpper(value);
    }

    // Always use lowerBound for types other than 1
    const boundToUse =
      studyPlan.studyPlanType === 1 ? "upperBound" : "lowerBound";
    const siteValue =
      property === boundToUse
        ? value
        : studyPlan.studyPlanType === 1
        ? sites_to_activateUpper
        : sites_to_activateLower;

    if (siteValue === "" || siteValue === null) {
      setSelectedEffort(null);
    } else {
      const numericValue = Number(siteValue);
      if (numericValue >= 1 && numericValue <= 2) {
        setSelectedEffort("Small");
      } else if (numericValue >= 3 && numericValue <= 10) {
        setSelectedEffort("Medium");
      } else if (numericValue > 10) {
        setSelectedEffort("Large");
      }
    }
  };

  const getEffortLabelValue = () => {
    if (studyPlan.studyPlanType === 1) {
      return selectedEffort && sites_to_activateUpper ? selectedEffort : "- - ";
    } else {
      return selectedEffort && sites_to_activateLower ? selectedEffort : "- - ";
    }
  };

  const showEffortDash = () => {
    if (studyPlan.studyPlanType === 1) {
      return selectedEffort && sites_to_activateUpper;
    } else {
      return selectedEffort && sites_to_activateLower;
    }
  };

  const isSaveButtonDisabled = () => {
    const requiredSites =
      studyPlan.studyPlanType === 1
        ? sites_to_activateUpper
        : sites_to_activateLower;
    const isAnySiteFieldMissing =
      studyPlan.studyPlanType === 1
        ? !sites_to_activateLower || !sites_to_activateUpper
        : !sites_to_activateLower;

    return (
      !selectedCountry ||
      !selectedEffort ||
      !selectedTimeframe ||
      !requiredSites ||
      isAnySiteFieldMissing ||
      loading
    );
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="add country"
      aria-describedby="add a country"
      title="Add Country"
    >
      <Container>
        <FormControl variant="outlined">
          <InputLabel id="country-label">Select Country</InputLabel>
          <Select
            labelId="county-label"
            id="select-country"
            value={selectedCountry}
            onChange={(event) => handleSelectCountry(event)}
            label="Select Country"
          >
            {siteGroups.map((siteGroup) => (
              <MenuItem
                value={siteGroup}
                key={siteGroup.siteGroupId}
              >
                {siteGroup.siteGroupName}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl variant="outlined">
          <div
            style={{
              display: "flex",
              gap: "1em",
              alignItems: "center",
            }}
          >
            <SpecialTextField
              disabled={selectedCountry === null}
              altlabel={studyPlan.studyPlanType !== 1 ? "" : "Lower Bound"}
              value={sites_to_activateLower}
              onChange={(value) => {
                handleUpdateSiteGroupParameters(
                  sites_to_activateLower,
                  "lowerBound",
                  value,
                );
              }}
              showArrows={false}
            />
            {studyPlan.studyPlanType === 1 && (
              <SpecialTextField
                disabled={selectedCountry === null}
                altlabel="Upper Bound"
                value={sites_to_activateUpper}
                onChange={(value) =>
                  handleUpdateSiteGroupParameters(
                    sites_to_activateUpper,
                    "upperBound",
                    value,
                  )
                }
                showArrows={false}
              />
            )}
            <p>Number of Activated Sites</p>
            <ToolTip
              zIndex={99999}
              info="Complete the range of sites targeted for each country; ensure the total number of sites across all countries will allow for scenarios that match the total number of sites entered in Overall Parameters; the modeling will return the quickest scenario to last patient enrolled."
            />
          </div>
        </FormControl>
        <Divider />
        <Typography>
          The below should be confirmed by the appropriate functional area.
        </Typography>
        <Label
          value={getEffortLabelValue()}
          variant="secondary"
        >
          Site ID Effort {showEffortDash() && " - "}
        </Label>
        <FormControl variant="outlined">
          <InputLabel id="country-startup-label">
            Select Country Start-Up Time Frame
          </InputLabel>

          <Select
            labelId="country-startup-label"
            id="select-country-startup"
            value={selectedTimeframe}
            onChange={(event) => setSelectedTimeframe(event.target.value)}
            label="Select Country Start-Up Time Frame"
          >
            {timeFrames.map(({ value, key }) => (
              <MenuItem
                key={value}
                value={value}
              >
                {value}{" "}
                {selectedCountry &&
                  `(${getDaysAndMonths(selectedCountry[key])})`}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <ButtonContainer>
          <OutlinedButton
            onClick={onClose}
            disabled={loading}
          >
            Cancel
          </OutlinedButton>
          <PrimaryButton
            onClick={handleSave}
            disabled={isSaveButtonDisabled()}
          >
            {loading ? <CircularProgress size="1em" /> : "Save"}
          </PrimaryButton>
        </ButtonContainer>
      </Container>
    </Modal>
  );
};

export default AddCountryModal;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 1em;
`;

const Container = styled.div`
  display: grid;
  grid-gap: 2em;
`;
