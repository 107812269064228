import React from "react";
import styled from "styled-components";

export default function TableRow({ sp, index }) {
  return (
    <Row>
      <Cell width={`${(8 / 70) * 100}%`}>
        <SmallText>{sp.cohortName}</SmallText>
      </Cell>
      <Cell width={`${(8 / 70) * 100}%`}>
        {index !== 0 && <SmallText>{sp.predecessorCohortName}</SmallText>}
      </Cell>
      <Cell width={`${(6 / 70) * 100}%`}>
        {index !== 0 && <SmallText>{sp.predecessorActivity}</SmallText>}
      </Cell>
      <Cell width={`${(3 / 70) * 100}%`}>
        <SmallText>{sp.patients}</SmallText>
      </Cell>
      <Cell
        className="remove_spin"
        width={`${(3 / 70) * 100}%`}
      >
        <SmallText>{sp.lagDays}</SmallText>
      </Cell>
      <Cell width={`${(6 / 70) * 100}%`}>
        <SmallText>{sp.cohortStartDate}</SmallText>
      </Cell>
      {/* <Cell width={`${(2 / 70) * 100}%`}>
        <SmallText>{sp.cohortStartDay}</SmallText>
      </Cell> */}
      <Cell width={`${(4 / 70) * 100}%`}>
        <SmallText>{sp.screeningDurationDays}</SmallText>
      </Cell>
      <Cell width={`${(5 / 70) * 100}%`}>
        <SmallText>{sp.screeningEndDate}</SmallText>
      </Cell>
      {/* <Cell width={`${(2 / 70) * 100}%`}>
        <SmallText>{sp.screeningEndDay}</SmallText>
      </Cell> */}
      <Cell width={`${(4 / 70) * 100}%`}>
        <SmallText>{sp.enrollingDurationDays}</SmallText>
      </Cell>
      <Cell width={`${(5 / 70) * 100}%`}>
        <SmallText>{sp.enrollingEndDate}</SmallText>
      </Cell>
      {/* <Cell width={`${(2 / 70) * 100}%`}>
        <SmallText>{sp.enrollingEndDay}</SmallText>
      </Cell> */}
      <Cell width={`${(4 / 70) * 100}%`}>
        <SmallText>{sp.dtlDurationDays}</SmallText>
      </Cell>
      <Cell width={`${(5 / 70) * 100}%`}>
        <SmallText>{sp.dtlEndDate}</SmallText>
      </Cell>
      {/* <Cell width={`${(2 / 70) * 100}%`}>
        <SmallText>{sp.dtlEndDay}</SmallText>
      </Cell> */}
      <Cell width={`${(4 / 70) * 100}%`}>
        <SmallText>{sp.srcDurationDays}</SmallText>
      </Cell>
      <Cell width={`${(5 / 70) * 100}%`}>
        <SmallText>{sp.srcEndDate}</SmallText>
      </Cell>
      {/* <Cell width={`${(2 / 70) * 100}%`}>
        <SmallText>{sp.srcEndDay}</SmallText>
      </Cell> */}
    </Row>
  );
}

const SmallText = styled.div`
  font-size: 14px;
`;

const Row = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  height: 50px;
  border-bottom: ${(p) =>
    p.noBorder ? "none" : "1px solid var(--surface-300)"};
  &:last-child {
    border: none;
  }
`;

const Cell = styled.div`
  background: ${(p) =>
    p.variant === "header" ? "var(--surface-100)" : "white"};
  height: 100%;
  align-items: center;
  justify-content: center;
  display: flex;
  width: ${(p) => (p.width ? p.width : "100%")};
  border-right: 1px solid var(--surface-300);
  padding: 5px;
  text-align: center;
  border-left: ${(p) =>
    p.leftBorder ? "1px solid var(--surface-300)" : "none"};
  &:last-child {
    border: ${(p) => (p.rightBorder ? "1px solid var(--surface-300)" : "none")};
  }
  border: ${(p) => (p.fullBorder ? "1px solid var(--surface-300)" : "")};
`;
