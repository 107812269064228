import React from "react";
import { Snackbar as MuiSnackbar, IconButton } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Alert, AlertTitle } from "@material-ui/lab";
import {
  Check,
  InfoOutlined,
  ReportProblemOutlined,
  CancelOutlined,
} from "@material-ui/icons";

const colors = {
  error: {
    bg: "var(--red-50)",
    border: "var(--red-600)",
    icon: "var(--red-600)",
  },
  success: {
    bg: "var(--green-50)",
    border: "var(--green-600)",
    icon: "var(--green-700)",
  },
  info: {
    bg: "var(--blue-50)",
    border: "var(--blue-500)",
    icon: "var(--blue-600)",
  },
  warning: {
    bg: "var(--yellow-50)",
    border: "var(--yellow-600)",
    icon: "var(--yellow-700)",
  },
};

const useStyles = makeStyles(() => ({
  content: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    width: "300px",
    borderRadius: "4px",
  },
  header: {
    display: "flex",
    alignItems: "center",
    width: "100%",
    justifyContent: "space-between",
  },
  title: { display: "flex", gap: "4px" },
  message: { paddingLeft: "26px" },
}));

const icons = {
  error: CancelOutlined,
  success: Check,
  info: InfoOutlined,
  warning: ReportProblemOutlined,
};

const Snackbar = ({ open, message, severity = "info", onClose }) => {
  const classes = useStyles();
  const { bg, border, icon } = colors[severity];
  const IconComponent = icons[severity];

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      onClose();
      return;
    }

    onClose();
  };

  return (
    <MuiSnackbar
      anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
      open={open}
      autoHideDuration={6000}
      onClose={handleClose}
    >
      <Alert
        onClose={onClose}
        severity={severity}
        style={{ backgroundColor: bg, border: `1px solid ${border}` }}
        icon={false}
      >
        <div className={classes.content}>
          <div className={classes.header}>
            <div className={classes.title}>
              <IconComponent
                fontSize="medium"
                style={{ color: icon, paddingBottom: "8px" }}
              />
              <AlertTitle style={{ color: icon }}>
                {severity.charAt(0).toUpperCase() + severity.slice(1)}
              </AlertTitle>
            </div>
            <IconButton
              size="small"
              color="inherit"
              onClick={onClose}
            />
          </div>
          <div className={classes.message}>{message}</div>
        </div>
      </Alert>
    </MuiSnackbar>
  );
};

export default Snackbar;
