import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import InfoIcon from "@material-ui/icons/Info";
import MuiTooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";

const ToolTip = ({
  info = "No info added.",
  changeColor = false,
  zIndex = 2,
}) => {
  const classes = useStyles({ changeColor, zIndex });

  return (
    <MuiTooltip
      arrow
      title={<Typography>{info}</Typography>}
      classes={{
        tooltip: classes.tooltip,
        popper: classes.popper,
      }}
    >
      <InfoIcon className={classes.icon} />
    </MuiTooltip>
  );
};

export default ToolTip;

const useStyles = makeStyles((theme) => ({
  icon: {
    color: "var(--teal-600)",
    marginLeft: ".3em",
  },
  tooltip: {
    maxWidth: "600px",
    borderRadius: "4px",
    backgroundColor: "var(--surface-600)",
  },
  popper: {
    zIndex: ({ zIndex }) => zIndex,
  },
}));
