import { Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { Route, Switch, useParams } from "react-router-dom";

import AddRouteComponentProp from "./common/edit.route/add.route.component.prop";
import CountriesAndSiteGroups from "./countries.and.site.groups";
import EditRoute from "./common/edit.route";
import Header from "../common/header";
import LinearProgress from "@material-ui/core/LinearProgress";
import OverallParameters from "./overall.parameters";
import Paper from "../common/paper";
import Review from "./review";
import Simulation from "./simulation";
import styled from "styled-components";
import { useQuery } from "../../app/hooks";
import { useStudyPlan } from "../../app/contexts/study.context";
import { useUser } from "../../app/contexts/user.context";
import validateStudyPlan from "./utils/validate.study.plan";

const StudyPlan = () => {
  const { planId, id } = useParams();
  const query = useQuery();
  const scenario = query.get("s");
  const sequentialCohort = query.get("seq");
  const {
    saveLoading,
    loadingPlan,
    fetchStudyPlan,
    seqCohortsStatus,
    fetchCohortStudyPlan,
    createNewPlan,
    studyPlan,
    STUDY_PLAN_TYPES,
    study,
  } = useStudyPlan();

  const { studyPlanType, studyPlanTypeDetail } = studyPlan || {};
  const { SCENARIO_A, SCENARIO_B } = STUDY_PLAN_TYPES;
  const [formKey, setFormKey] = useState(null);
  const initialValues = studyPlan || {};
  const { setURLStudyId } = useUser();

  useEffect(() => {
    setURLStudyId(id);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (
      studyPlan &&
      studyPlanType === 2 &&
      studyPlanTypeDetail === "Cohort (D)" &&
      seqCohortsStatus === "idle"
    ) {
      fetchCohortStudyPlan(
        planId,
        process.env.REACT_APP_USERNAME,
        process.env.REACT_APP_PASSWORD,
      );
    }
    // eslint-disable-next-line
  }, [studyPlan, seqCohortsStatus, studyPlanType]);

  useEffect(() => {
    if (!sequentialCohort) createNewPlan();
    if (planId.includes("new-plan_")) {
      createNewPlan(scenario, sequentialCohort);
    } else {
      fetchStudyPlan(
        planId,
        process.env.REACT_APP_USERNAME,
        process.env.REACT_APP_PASSWORD,
      );
    }
    // eslint-disable-next-line
  }, [planId]);

  useEffect(() => {
    if (studyPlan && !formKey) setFormKey(new Date().getTime());
  }, [studyPlan, formKey]);

  if (loadingPlan || saveLoading) {
    return (
      <Container>
        <Paper>
          <LinearProgress />
        </Paper>
      </Container>
    );
  }

  return (
    <Container>
      <Paper>
        <Formik
          key={formKey}
          validate={() =>
            validateStudyPlan(
              studyPlan,
              studyPlanType,
              SCENARIO_A,
              SCENARIO_B,
              study,
            )
          }
          initialValues={{ ...initialValues, updated: new Date().getTime() }}
        >
          {({ errors, values, touched, setValues }) => (
            <Form>
              <HeaderContainer>
                <Header />
              </HeaderContainer>
              <Switch>
                <EditRoute
                  exact
                  path="/ctpo/study-plans/:id/:planId/"
                  component={OverallParameters}
                  additionalProps={{
                    values: values,
                    errors: errors,
                    setValues: setValues,
                    step: 1,
                  }}
                />
                <EditRoute
                  path="/ctpo/study-plans/:id/:planId/countries-and-site-groups"
                  component={CountriesAndSiteGroups}
                  additionalProps={{
                    values: values,
                    errors: errors,
                    setValues: setValues,
                    touched: touched,
                    step: 2,
                  }}
                />
                <AddRouteComponentProp
                  path="/ctpo/study-plans/:id/:planId/review"
                  component={Review}
                  additionalProps={{
                    values,
                    errors,
                    setValues,
                  }}
                />
                <Route
                  path="/ctpo/study-plans/:id/:planId/simulation"
                  component={Simulation}
                />
              </Switch>
            </Form>
          )}
        </Formik>
      </Paper>
    </Container>
  );
};

export default StudyPlan;

const Container = styled.div`
  min-width: 1300px;
  width: 100%;
  min-height: 100vh;
`;

const HeaderContainer = styled.div`
  position: sticky;
  top: 5em;
  z-index: 110;
  border-radius: 4px;
  background: var(--teal-600);
  padding: 1em 1em 0 0;
`;
