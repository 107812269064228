import React, { useMemo } from "react";
import { useStudyPlan } from "../../../../app/contexts/study.context";
import styled from "styled-components";
import { useScheduleEvents } from "../../../../app/contexts/schedule.events.context";
import TitleLabel from "../../simulation/summary.of.overall.parameters/title.label";

const labels = {
  studyName: "Study Title",
  scheduleOfEventsName: "SOE Name",
  scheduleOfEventsDescr: "SOE Description",
  studyPlanName: "Linked Enrollment Plan (Study Plan)",
};

export default function Header() {
  const { currentSOE } = useScheduleEvents();
  const { study } = useStudyPlan();
  const { studyPlans } = study || {};

  const { scheduleOfEventsName, scheduleOfEventsDescr, studyPlanId } =
    currentSOE || {};

  const studyPlanName = useMemo(
    () =>
      studyPlans.find((sp) => sp.studyPlanId === studyPlanId)?.studyPlanName,
    [studyPlans, studyPlanId],
  );
  return (
    <MainContainer>
      {/* <TitleLabel value={studyName} label={labels["studyName"]} /> */}
      <TitleLabel
        value={studyPlanName}
        label={labels["studyPlanName"]}
      />
      <TitleLabel
        value={scheduleOfEventsName}
        label={labels["scheduleOfEventsName"]}
      />
      <TitleLabel
        value={scheduleOfEventsDescr}
        label={labels["scheduleOfEventsDescr"]}
      />
    </MainContainer>
  );
}

const MainContainer = styled.div`
  display: grid;
  grid-gap: 0.5em;
  margin-bottom: 1em;
  margin-left: 0.5em;
`;
