import React from "react";
import styled from "styled-components";
import { SectionHeader } from "../../../../app/components";
import { useHistory, useParams } from "react-router-dom";
import { useStudyPlan } from "../../../../app/contexts/study.context";
import EditIcon from "@material-ui/icons/Edit";
import ScenarioA from "./scenario.a";
import ScenarioB from "./scenario.b";
import SeparateContainer from "../../../../app/components/separate.Container";
import TextButton from "../../../../app/components/buttons/text";

const OverallParameters = () => {
  const history = useHistory();
  const { id, planId } = useParams();
  const { studyPlan, STUDY_PLAN_TYPES, canEditPlan } = useStudyPlan();

  const { studyPlanType } = studyPlan;
  const { SCENARIO_A, SCENARIO_B } = STUDY_PLAN_TYPES;
  let scenarioRender;
  if (studyPlanType === SCENARIO_A) {
    scenarioRender = <ScenarioA />;
  }

  if (studyPlanType === SCENARIO_B) {
    scenarioRender = <ScenarioB />;
  }

  return (
    <SeparateContainer>
      <Container>
        <Header>
          <SectionHeader paddingBottom={false}>
            Overall Study Inputs
          </SectionHeader>
          {canEditPlan && (
            <TextButton
              style={{ marginBlock: "1em", marginInline: "0.6em" }}
              onClick={() =>
                history.push(`/ctpo/study-plans/${id}/${planId}#overall-inputs`)
              }
            >
              <EditIcon style={{ marginRight: ".3em" }} /> Edit
            </TextButton>
          )}
        </Header>
        <div style={{ padding: "20px" }}>
          <Content>{scenarioRender}</Content>
        </div>
      </Container>
    </SeparateContainer>
  );
};

export default OverallParameters;

const Container = styled.div``;
const Header = styled.div`
  display: flex;
  justify-content: space-between;
  background-color: var(--surface-50);
  color: var(--text-primary);
  border-radius: 4px;
`;
const Content = styled.div``;
