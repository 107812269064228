import React from "react";
import styled from "styled-components";
import { SectionHeader, Tooltip } from "../../../app/components";
import Typography from "@material-ui/core/Typography";
import SeparateContainer from "../../../app/components/separate.Container";
import SideBySide from "../../../app/components/sideBySide";

export default function ActivationProfiles({
  activationProfiles = [],
  tooltip,
}) {
  return (
    <Container>
      <SeparateContainer>
        <SideBySide>
          <SectionHeader
            hasStartAdornment={false}
            marginBottom={false}
            endAdornment={
              tooltip && (
                <Tooltip
                  changeColor={true}
                  info={tooltip}
                />
              )
            }
          >
            Site Group Activation Profiles
          </SectionHeader>
        </SideBySide>
        <div style={{ padding: "10px 20px 10px 20px" }}>
          <Table>
            <Row repeat={activationProfiles.length}>
              <Cell variant="header">
                <Typography align="right">Percentage</Typography>
              </Cell>
              {activationProfiles.map((profile) => (
                <Cell
                  key={`Pct-${
                    profile.siteActivationProfileId || profile.id
                  }-View`}
                >
                  <Typography align="center">
                    {profile.siteActivationProfilePct}
                  </Typography>
                </Cell>
              ))}
            </Row>
            <Row repeat={activationProfiles.length}>
              <Cell variant="header">
                <Typography align="right">Days</Typography>
              </Cell>
              {activationProfiles.map((profile) => (
                <Cell
                  key={`Days-${
                    profile.siteActivationProfileId || profile.id
                  }-View`}
                >
                  <Typography align="center">
                    {profile.siteActivationProfileDays}
                  </Typography>
                </Cell>
              ))}
            </Row>
          </Table>
        </div>
      </SeparateContainer>
    </Container>
  );
}

const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 1em;
`;

const Row = styled.div`
  display: grid;
  grid-template-columns: 120px repeat(${(p) => p.repeat}, 1fr);
  height: 50px;
  border-bottom: 1px solid var(--surface-300);
  &:last-child {
    border: none;
  }
`;

const Cell = styled.div`
  background: ${(p) =>
    p.variant === "header" ? "var(--surface-100)" : "var(--White)"};
  height: 100%;
  align-items: center;
  justify-content: center;
  display: flex;
  border-right: 1px solid var(--surface-300);
  padding: 0.5em;
  &:last-child {
    border: none;
  }
  &:first-child {
    justify-content: flex-end;
    padding-right: 1em;
  }
`;

const Table = styled.div`
  display: grid;
  width: 100%;
  grid-template-rows: auto auto;
  border: 1px solid var(--surface-300);
  border-radius: 4px;
`;
