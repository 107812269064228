import React from "react";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/styles";

const OutlinedButton = (props) => {
  const classes = useStyles();

  return (
    <Button
      className={classes.secondaryButton}
      {...props}
    >
      {props.children}
    </Button>
  );
};

export default OutlinedButton;

const useStyles = makeStyles((theme) => ({
  secondaryButton: {
    border: "1px solid var(--teal-700)",
    color: "var(--teal-700)",
    backgroundColor: "var(--White)",
    transition: "background-color 0.2s ease",
    "&:hover": {
      backgroundColor: "var(--teal-100)",
    },
    "&:disabled": {
      opacity: 0.6,
      color: "var(--teal-700)",
      cursor: "not-allowed",
    },
    "&:active": {
      backgroundColor: "var(--teal-300)",
    },
    "&:focus": {
      outline: "1px solid var(--teal-500)",
      outlineOffset: "4px",
    },
  },
}));
