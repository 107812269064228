import React, { useEffect, useState } from "react";
import API from "../../../../app/utils/api";
import { Autocomplete } from "@material-ui/lab";
import { CircularProgress } from "@material-ui/core";
import { Modal } from "../../../../app/components";
import TextField from "@material-ui/core/TextField";
import { debounce } from "lodash";
import { makeStyles } from "@material-ui/core/styles";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import { useStudyPlan } from "../../../../app/contexts/study.context";
import PrimaryButton from "../../../../app/components/buttons/primary";
import OutlinedButton from "../../../../app/components/buttons/outlined";

const CloneStudyPlan = ({ open = false, onClose = (noop) => noop, plan }) => {
  const [working, setWorking] = useState(false);
  const { study } = useStudyPlan();
  const history = useHistory();
  const classes = useStyles();
  const [newStudyPlanName, setNewStudyPlanName] = useState(
    `${plan.studyPlanName} - Clone`,
  );
  const [newDescription, setNewDescription] = useState(
    `${plan?.studyPlanDescr ? plan.studyPlanDescr : ""}`,
  );
  const [isStudyPlanNameNotUnique, setIsStudyPlanNameNotUnique] =
    useState(false);

  const [cloneToStudyId, setCloneToStudyId] = useState(study.studyId);
  const [cloneToStudyValue, setCloneToStudyValue] = useState(study.projectId);
  const [options, setOptions] = useState([study]);
  const [studyPlans, setStudyPlans] = useState(study.studyPlans);

  useEffect(() => {
    // check if the cloneToStudyId not equal to study.id
    try {
      if (open) {
        API.getStudy(cloneToStudyId).then((res) => {
          if (res && res.data && res.data[0] && res.data[0].studyPlans)
            setStudyPlans(res?.data[0]?.studyPlans || []);
          else setStudyPlans([]);
        });
      }
    } catch (err) {
      console.log(err);
    }
  }, [cloneToStudyId, study.studyId, open]);

  useEffect(() => {
    if (!open) return;
    // study plans is empty or null set isStudyPlanNameNotUnique to false
    if (!studyPlans || studyPlans.length === 0) {
      setIsStudyPlanNameNotUnique(false);
      return;
    }

    // eslint-disable-next-line
    studyPlans?.every((studyPlan, index) => {
      if (
        studyPlan?.studyPlanName?.toLowerCase().trim() ===
        newStudyPlanName?.toLowerCase().trim()
      ) {
        setIsStudyPlanNameNotUnique(true);
        return false;
      }

      if (index === studyPlans.length - 1) {
        setIsStudyPlanNameNotUnique(false);
        return false;
      }
      return true;
    });
  }, [newStudyPlanName, studyPlans, open]);

  const handleCloneAndEdit = () => {
    const { studyPlanId } = plan;
    const new_plan = {
      studyPlanId,
      studyPlanName: newStudyPlanName,
      studyPlanDescr: newDescription,
      scenarioStatus: "",
      ownedBy: "",
      studyId: cloneToStudyId,
    };
    setWorking(true);
    API.createNewClone(new_plan)
      .then((res) => {
        setWorking(false);
        history.push(
          `/ctpo/study-plans/${cloneToStudyId}/${res.data[0].studyPlanId}/review`,
        );
      })
      .catch((err) => {
        setWorking(false);
        console.error(err);
      });
  };

  const handleSearch = debounce((text) => {
    API.getStudies(text).then((res) => setOptions(res.data));
  }, 300);

  const handleChange = (event, selected) => {
    if (!selected || (!selected?.studyId && selected?.studyId !== 0)) {
      setCloneToStudyValue(null);
      setCloneToStudyId(null);
      return;
    }
    setCloneToStudyValue(selected.projectId);
    setCloneToStudyId(selected.studyId);
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="clone study plan"
      aria-describedby="clone a study plan"
      title="Clone Study Plan"
    >
      <Container>
        <TextField
          value={newStudyPlanName}
          onChange={(e) => setNewStudyPlanName(e.target.value)}
          label="Study Plan Name"
          variant="outlined"
          InputLabelProps={{ classes: classes }}
          fullWidth
          required
          inputProps={{
            maxLength: 50,
          }}
          helperText={
            <AlignBetween>
              <div>
                {isStudyPlanNameNotUnique && "Study Plan Name must be unique"}
              </div>
              <div>{`${(newStudyPlanName || "").length} / ${50}`}</div>
            </AlignBetween>
          }
          error={isStudyPlanNameNotUnique}
        />
        <TextField
          value={newDescription}
          onChange={(e) => setNewDescription(e.target.value)}
          label="Study Plan Description"
          variant="outlined"
          InputLabelProps={{ classes: classes }}
          multiline
          rows="4"
          fullWidth
          required
        />

        <Autocomplete
          value={cloneToStudyValue}
          id="autocomplete"
          freeSolo
          classes={classes}
          options={options}
          onChange={handleChange}
          getOptionLabel={(option) => {
            if (!option?.projectId || !option.studyName) {
              return option;
            }
            return `${option.projectId}`;
          }}
          error={cloneToStudyId === null}
          renderInput={(params) => (
            <TextField
              {...params}
              onChange={(e) =>
                handleSearch(e.target.value.replace(/(\/|#|\\)/g, ""))
              }
              label="Copy To Project"
              variant="outlined"
              placeholder="Search..."
              helperText="Search by PCN"
              InputProps={{ ...params.InputProps }}
              inputProps={{
                ...params.inputProps,
              }}
            />
          )}
        />
        <SideBySide>
          <OutlinedButton onClick={onClose}>Cancel </OutlinedButton>
          <PrimaryButton
            onClick={handleCloneAndEdit}
            disabled={
              working ||
              !newStudyPlanName ||
              !newDescription ||
              isStudyPlanNameNotUnique ||
              !cloneToStudyId
            }
          >
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {!working ? (
                <span>Clone & Edit Details</span>
              ) : (
                <span>
                  <CircularProgress size="1em" />{" "}
                </span>
              )}
            </div>
          </PrimaryButton>
        </SideBySide>
      </Container>
    </Modal>
  );
};

export default CloneStudyPlan;

const Container = styled.div`
  display: grid;
  grid-gap: 2em;
`;
const SideBySide = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 1em;
`;

const AlignBetween = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

const useStyles = makeStyles((theme) => ({
  asterisk: {
    color: "red",
  },
}));
