import React, { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import Button from "@material-ui/core/Button";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import EditScheduleOfEventModal from "../../../schedule.events.table/table.row/edit.schedule.event.modal";
import UnsavedChangesModal from "../../soe/unsaved.changes.modal";
import UploadSOEProtocolModal from "./upload.soe.potocol.modal";
import styled from "styled-components";
import { useScheduleEvents } from "../../../../app/contexts/schedule.events.context";
import { useSnackbar } from "../../../../app/contexts/snackbar.context";
import OutlinedButton from "../../../../app/components/buttons/outlined";
import PrimaryButton from "../../../../app/components/buttons/primary";
import TextButton from "../../../../app/components/buttons/text";

const Controls = ({
  errors,
  editOpen,
  setEditOpen,
  hasChangesToSave,
  setUpdateContext,
  saveSOE,
  value,
}) => {
  const history = useHistory();
  const { id, scheduleId } = useParams();
  const [fileUploadOpen, setFileUploadOpen] = useState(false);
  const [unsavedModalOpen, setUnsavedModalOpen] = useState(false);

  const {
    hasSOEToSave,
    handleSOESave,
    loadingSOEStudy,
    checkSOEValid,
    fetchSOEVisits,
    fetchSOEAssessments,
    fetchSOEMapping,
    setScheduleOfEventsVisitsChangeLog,
    setScheduleOfEventsAssessmentsChangeLog,
    setVisitsAndAssessmentsMappingChangeLog,
    updateSOE,
    fetchSOEStudy,
    fetchSOECountries,
    fetchRiskAssessments,
    fetchVisitSampleStrategy,
    setScheduleOfEventsVisits,
    scheduleOfEventsVisits,
    scheduleOfEventsAssessments,
    currentSOE,
    SOECountries,
  } = useScheduleEvents();

  const { showSnackbar } = useSnackbar();

  const checkForCostData = () => {
    // Get unique countries (excluding Study)
    const uniqueCountries = Array.from(
      new Set(
        SOECountries.filter(
          (country) => country.countryCodeISO3 !== "Study",
        ).map((country) => country.countryCodeISO3),
      ),
    ).map((countryCode) =>
      SOECountries.find((country) => country.countryCodeISO3 === countryCode),
    );

    // Study Level Validations
    const hasStudyVisitCosts = scheduleOfEventsVisits["study"].some(
      (visit) =>
        visit.visitCost !== null &&
        visit.visitCost !== undefined &&
        visit.visitCost !== 0,
    );

    const hasStudyVisitOverheads = scheduleOfEventsVisits["study"].some(
      (visit) =>
        visit.visitOverheadPct !== null &&
        visit.visitOverheadPct !== undefined &&
        visit.visitOverheadPct !== 0,
    );

    const hasStudyAssessmentCosts = scheduleOfEventsAssessments["study"].some(
      (assessment) =>
        assessment.assessmentCost !== null &&
        assessment.assessmentCost !== undefined &&
        assessment.assessmentCost !== 0,
    );

    const hasStudyAssessmentOverheads = scheduleOfEventsAssessments[
      "study"
    ].some(
      (assessment) =>
        assessment.overhead !== null &&
        assessment.overhead !== undefined &&
        assessment.overhead !== 0,
    );

    // Country and Site Level Validations
    const hasCountryOrSiteLevelCosts = uniqueCountries.some((country) => {
      // Get all keys from scheduleOfEventsVisits and scheduleOfEventsAssessments that match this country's siteGroupId
      const visitKeys = Object.keys(scheduleOfEventsVisits).filter(
        (key) =>
          key.startsWith(`${country.siteGroupId}-`) &&
          key !== `${country.siteGroupId}-1`,
      );
      const assessmentKeys = Object.keys(scheduleOfEventsAssessments).filter(
        (key) =>
          key.startsWith(`${country.siteGroupId}-`) &&
          key !== `${country.siteGroupId}-1`,
      );

      // Country level checks
      const countryKey = `${country.siteGroupId}-1`;

      const hasCountryVisitCosts = scheduleOfEventsVisits[countryKey]?.some(
        (visit) =>
          visit.visitCost !== null &&
          visit.visitCost !== undefined &&
          visit.visitCost !== 0,
      );

      const hasCountryVisitOverheads = scheduleOfEventsVisits[countryKey]?.some(
        (visit) =>
          visit.visitOverheadPct !== null &&
          visit.visitOverheadPct !== undefined &&
          visit.visitOverheadPct !== 0,
      );

      const hasCountryAssessmentCosts = scheduleOfEventsAssessments[
        countryKey
      ]?.some(
        (assessment) =>
          assessment.assessmentCost !== null &&
          assessment.assessmentCost !== undefined &&
          assessment.assessmentCost !== 0,
      );

      // Site level checks
      const hasSiteLevelCosts = [
        ...new Set([...visitKeys, ...assessmentKeys]),
      ].some((siteKey) => {
        const hasSiteVisitCosts = scheduleOfEventsVisits[siteKey]?.some(
          (visit) =>
            visit.visitCost !== null &&
            visit.visitCost !== undefined &&
            visit.visitCost !== 0,
        );

        const hasSiteVisitOverheads = scheduleOfEventsVisits[siteKey]?.some(
          (visit) =>
            visit.visitOverheadPct !== null &&
            visit.visitOverheadPct !== undefined &&
            visit.visitOverheadPct !== 0,
        );

        const hasSiteAssessmentCosts = scheduleOfEventsAssessments[
          siteKey
        ]?.some(
          (assessment) =>
            assessment.assessmentCost !== null &&
            assessment.assessmentCost !== undefined &&
            assessment.assessmentCost !== 0,
        );

        return (
          hasSiteVisitCosts || hasSiteVisitOverheads || hasSiteAssessmentCosts
        );
      });

      return (
        hasCountryVisitCosts ||
        hasCountryVisitOverheads ||
        hasCountryAssessmentCosts ||
        hasSiteLevelCosts
      );
    });

    return (
      hasStudyVisitCosts ||
      hasStudyVisitOverheads ||
      hasStudyAssessmentCosts ||
      hasStudyAssessmentOverheads ||
      hasCountryOrSiteLevelCosts
    );
  };

  const handleUploadClick = () => {
    if (checkForCostData()) {
      showSnackbar(
        "Cost data has been detected on this SOE. As a result, uploading of a new SOE to replace the data is being prevented. If adjustments are needed to the SOE either create a new SOE or make the edits directly to the SOE in the Visit, Assessment and SOE Mapping tables.",
        "error",
      );
    } else {
      setFileUploadOpen(true);
    }
  };

  const resetChanges = () => {
    setScheduleOfEventsVisitsChangeLog({});
    setScheduleOfEventsAssessmentsChangeLog({});
    setVisitsAndAssessmentsMappingChangeLog({});
  };

  const handleBackToList = () => {
    resetChanges();
    history.push(`/ctpo/study-plans/${id}`);
  };

  const handleCancel = () => {
    setUnsavedModalOpen(true);
  };

  const callback = async (withoutLoading = false) => {
    await setTimeout(async () => {
      await fetchSOEVisits(scheduleId, id, null, null, null, withoutLoading);
      await fetchSOEAssessments(
        scheduleId,
        id,
        null,
        null,
        null,
        withoutLoading,
      );
      await fetchSOEMapping(scheduleId, withoutLoading);
      await fetchSOECountries(scheduleId);
      await fetchRiskAssessments(scheduleId);
      await fetchVisitSampleStrategy(scheduleId);
      await fetchSOEStudy(id);
    }, 200);
  };

  const handleSave = async (validate = true) => {
    try {
      let [formsValid, lErrorMessages] = checkSOEValid();
      if (!formsValid && validate) {
        showSnackbar(
          <ul>
            {Object.keys(lErrorMessages).map((key) => {
              return (
                <li key={key}>
                  {lErrorMessages[key].message}{" "}
                  {lErrorMessages[key].count > 1 && (
                    <ErrorMessageCount>
                      {lErrorMessages[key].count}
                    </ErrorMessageCount>
                  )}
                </li>
              );
            })}
          </ul>,
          "error",
        );
      } else {
        localStorage.setItem("temporal-save-flag", true);
        setScheduleOfEventsVisits((prevScheduleOfEventsVisits) => {
          const updatedStudy = prevScheduleOfEventsVisits.study.map((event) => {
            if (
              event.scheduleOfEventsVisitId &&
              String(event.scheduleOfEventsVisitId).startsWith("1000")
            ) {
              return {
                ...event,
                scheduleOfEventsVisitId: undefined,
              };
            }
            return event;
          });

          return {
            ...prevScheduleOfEventsVisits,
            study: updatedStudy,
          };
        });
        await handleSOESave(id, scheduleId, callback);
        showSnackbar("Saved successfully", "success");
        return true;
      }
    } catch (err) {
      console.log(err);
      showSnackbar("Failed to save soe", "error");
    }
    return false;
  };

  useEffect(() => {
    if (saveSOE && localStorage.getItem("temporal-save-flag") === null) {
      handleSave();
      setUpdateContext(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [saveSOE]);

  const handleForecastSOE = () => {
    history.push(`/ctpo/study-plans/${id}/soe/${scheduleId}/forecast`);
  };

  const redirect = async () => {
    await fetchSOEStudy(id);
  };

  const updateSOEHandler = async (soeValue) => {
    try {
      await updateSOE(soeValue, redirect);
      showSnackbar("Saved successfully", "success");
      setEditOpen((prev) => !prev);
      window.location.reload(false);
    } catch (err) {
      console.log(err);
      showSnackbar("Failed to save soe", "error");
    }
  };

  return (
    <Container>
      {/* <Button
        onClick={handleBackToList}
        startIcon={<ChevronLeftIcon />}
        style={{
          justifyContent: "flex-start",
          paddingLeft: 0,
          color: "var(--Primary-color)",
        }}
      >
        <span style={{ marginLeft: "auto" }}>Back to SOE List</span>
      </Button> */}
      <TextButton
        style={{ marginRight: "auto" }}
        onClick={handleBackToList}
      >
        <ChevronLeftIcon />
        Back to SOE List
      </TextButton>

      <ButtonContainer>
        <OutlinedButton
          onClick={handleCancel}
          disabled={(!hasSOEToSave && !hasChangesToSave) || loadingSOEStudy}
        >
          Cancel
        </OutlinedButton>
        <OutlinedButton onClick={() => setEditOpen(true)}>Edit</OutlinedButton>
        <PrimaryButton
          disabled={(!hasSOEToSave && !hasChangesToSave) || loadingSOEStudy}
          onClick={() => {
            if (value === 0) {
              localStorage.removeItem("temporal-save-flag");
              setUpdateContext(true);
            } else {
              handleSave();
            }
          }}
        >
          Save
        </PrimaryButton>
        <PrimaryButton onClick={handleUploadClick}>Upload</PrimaryButton>
        <OutlinedButton onClick={handleForecastSOE}>REPORTS</OutlinedButton>
      </ButtonContainer>

      {editOpen && (
        <EditScheduleOfEventModal
          soe={{ scheduleOfEventsId: scheduleId }}
          open={editOpen}
          onClose={() => setEditOpen(false)}
          onContinue={updateSOEHandler}
        />
      )}

      {fileUploadOpen && (
        <UploadSOEProtocolModal
          soe={currentSOE}
          open={fileUploadOpen}
          onClose={() => setFileUploadOpen(false)}
          onContinue={updateSOEHandler}
          fetchSOEValues={callback}
        />
      )}

      {
        <UnsavedChangesModal
          open={unsavedModalOpen}
          onToggle={() => setUnsavedModalOpen(!unsavedModalOpen)}
          handleSave={handleSave}
          handleBackToList={handleBackToList}
        />
      }
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 1em;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  grid-gap: 1em;
`;

const ErrorMessageCount = styled.span`
  display: inline-block;
  font-size: 12px;
  padding: 0em 0.6em;
  border: 1px solid red;
  border-radius: 0.8em;
  color: red;
`;

export default Controls;
