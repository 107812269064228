import React from "react";
import styled from "styled-components";
import { Modal } from "../../../app/components";
import { useStudyPlan } from "../../../app/contexts/study.context";
import Typography from "@material-ui/core/Typography";
import { useHistory, useParams } from "react-router-dom";
import PrimaryButton from "../../../app/components/buttons/primary";
import OutlinedButton from "../../../app/components/buttons/outlined";

const UnsavedChangesModal = ({ open = false, onToggle, to }) => {
  const {
    savePlan,
    clearChangeLog,
    fetchStudyPlan,
    fetchCohortStudyPlan,
    isSequential,
  } = useStudyPlan();
  const history = useHistory();
  const { id, planId } = useParams();

  const handleSaveAndExit = () => {
    handleSave();
    to ? redirect() : handleBackToList();
  };

  const handleExitWithoutSaving = async () => {
    if (to) {
      fetchStudyPlan(planId);
      isSequential && fetchCohortStudyPlan(planId);
      redirect();
    } else {
      handleBackToList();
    }
    to ? redirect() : handleBackToList();
    clearChangeLog();
  };

  const handleBackToList = () => history.push(`/ctpo/study-plans/${id}`);

  const handleSave = () => {
    const isNew = planId.includes("new-plan_") ? true : false;

    savePlan({ isNew, redirect });
  };

  const redirect = () =>
    to ? history.push(to) : history.push(`/ctpo/study-plans/${id}`);

  return (
    <Modal
      open={open}
      onClose={onToggle}
      aria-labelledby="clone study plan"
      aria-describedby="clone a study plan"
      title="You Have Unsaved Changes"
    >
      <Typography>
        Are you sure you want to exit without saving your changes first? Select
        an action below to continue.
      </Typography>
      <Container>
        <OutlinedButton onClick={handleExitWithoutSaving}>
          Exit Without Saving
        </OutlinedButton>
        <PrimaryButton onClick={handleSaveAndExit}>Save & Exit</PrimaryButton>
      </Container>
    </Modal>
  );
};

export default UnsavedChangesModal;

const Container = styled.div`
  margin-top: 2em;
  display: flex;
  justify-content: flex-end;
  gap: 1em;
`;
